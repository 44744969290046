import i18next from 'i18next';

export const required = value =>
  value || typeof value === 'string'
    ? undefined
    : i18next.t('validators_required');

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? i18next.t('validators_email')
    : undefined;

export const minLength = min => value =>
  value && value.length < min
    ? i18next.t('validators_min_length', { min })
    : undefined;

export const maxLength = max => value =>
  value && value.length > max
    ? i18next.t('validators_max_length', { max })
    : undefined;

export const maxLength24 = maxLength(24);
export const maxLength100 = maxLength(100);
export const maxLength2000 = maxLength(2000);

export const minLength2 = minLength(2);
export const minLength6 = minLength(6);

export const maxBirthdayDate = () => {
  return `${new Date().getFullYear() - 10}-01-01`;
};

export const minBirthdayDate = () => {
  return `${new Date().getFullYear() - 60}-01-01`;
};

export const maxDate = value => {
  const maxYear = new Date().getFullYear() - 10;
  const currentYear = new Date(value).getFullYear();

  if (currentYear > maxYear) {
    return i18next.t('validators_max_data');
  }

  if (!currentYear) {
    return i18next.t('validators_incorect_data');
  }

  return undefined;
};
