import React from 'react';
import { useSelector } from 'react-redux';

import { globalLoading } from '../../../../redux/selectors';
import { LottieAnimation } from '../LottieAnimation';
import { globalLoaderConfig, globalLoaderTypes } from './GlobalLoader.config';

import './styles.scss';

export const GlobalLoader = () => {
  const { loadingTypes } = useSelector(globalLoading);

  return (
    <div
      className={`global-loader ${
        loadingTypes === globalLoaderTypes.secondary ? 'background' : ''
      }`}
    >
      <LottieAnimation lotti={globalLoaderConfig[loadingTypes]} />
    </div>
  );
};
