import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import book from 'constants/book';

import './styles.scss';

export const NotificationModal = props => {
  const { message, closeNotificationModal, notificationType } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return function cleanup() {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });

  const handleKeyPress = e => {
    if (e.code !== 'Escape') return;

    dispatch(closeNotificationModal());
  };

  return (
    <div className="notifcation-modal__backdrop">
      <div className="notifcation-modal__content">
        <div
          onClick={() => dispatch(closeNotificationModal())}
          className="notifcation-modal__close-button"
        >
          <CloseIcon className="notifcation-modal__close-button-icon" />
        </div>

        <div className="notifcation-modal__content-text-container">
          <p className="notifcation-modal__content-text">{t(message)}</p>
        </div>
        {notificationType === 'reaction' && (
          <Link
            className="notifcation-modal__content-login-button"
            to={book.login}
            onClick={() => dispatch(closeNotificationModal())}
          >
            <p className="notifcation-modal__content-login-button-text">
              {t('signin_button')}
            </p>
          </Link>
        )}
      </div>
    </div>
  );
};
