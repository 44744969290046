const types = {
  GET_TOURNAMENTS_REQUEST: 'tournaments/GET_TOURNAMENTS_REQUEST',
  GET_TOURNAMENTS_SUCCESS: 'tournaments/GET_TOURNAMENTS_SUCCESS',
  GET_TOURNAMENTS_ERROR: 'tournaments/GET_TOURNAMENTS_ERROR',

  GET_TOURNAMENT_BY_ID_REQUEST: 'tournaments/ GET_TOURNAMENT_BY_ID_REQUEST',
  GET_TOURNAMENT_BY_ID_SUCCESS: 'tournaments/ GET_TOURNAMENT_BY_ID_SUCCESS',
  GET_TOURNAMENT_BY_ID_ERROR: 'tournaments/ GET_TOURNAMENT_BY_ID_ERROR',
};

export default types;
