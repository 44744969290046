import React from 'react';
import ReactDOM from 'react-dom';

import firebase from '@firebase/app';

import './localization/i18n';

import { firebaseConfig } from './firebase/analytics';
import ErrorBoundary from './views/component/Error/ErrorBoundary/ErrorBoundary';
import Main from './views/main/main';

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <ErrorBoundary>
    <Main />
  </ErrorBoundary>,
  document.getElementById('root'),
);
