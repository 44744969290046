import { get, post } from '../apiService';

export const googleAuth = url => {
  window.open('https://server.gmt.gg/api/v1/auth/google', '_self');
};

export const facebookAuth = url => {
  window.open('https://server.gmt.gg/api/v1/auth/facebook', '_self');
};

export const checkAuth = () => {
  return get('/auth/check-auth');
};

export const logoutSocialAuth = () => {
  return get('/auth/logout');
};

export const login = body => post('/auth/signin', body);

export const signup = body => post('/auth/signup', body);

export const forgotPassword = body => post('/user/forgot-password', body);

export const resetPassword = (token, body) =>
  post(`/user/reset-password/${token}`, body);

export const confirmUser = code => get(`/user/confirm/${code}`);

export const sendConfirmCode = () => post('/confirm/send-confirm-code');

export const updateToken = body => post('/auth/update-token', body);

export const logout = () => post('/auth/update-token');
