import { types } from './partnersTypes';

const initialState = {
  allPartners: { items: [], count: 0 },
  loader: true,
  error: '',
};

export const partnersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PARTNERS_REQUEST:
      return { ...state, loader: true };

    // data
    case types.GET_PARTNERS_SUCCESS:
      return { ...state, loader: false, allPartners: payload };

    // error
    case types.GET_PARTNERS_ERROR:
      return { ...state, error: payload, loader: false };

    default:
      return state;
  }
};
