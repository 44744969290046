import React from 'react';
import { useSelector } from 'react-redux';

import rosterAnimation from 'animation/titlesLoader/roster.json';
import { useSharedClassContext } from 'contexts';
import { rosterPlayersSelector } from 'redux/selectors';
import { LottieAnimation, ProgressiveImage } from 'views/component';

import './styles.scss';

export const Header = () => {
  const { appendClass } = useSharedClassContext();

  const { isLoading, random } = useSelector(rosterPlayersSelector);

  const imageOptions = {
    className: appendClass('__header-player-image'),
  };

  return (
    <div className={appendClass('__header')}>
      <div className={appendClass('__header-title')}>
        <LottieAnimation lotti={rosterAnimation} />
      </div>
      <span className={appendClass('__header-player')}>
        <ProgressiveImage
          image={random}
          isLoading={isLoading}
          alt="player"
          options={imageOptions}
        />
      </span>
    </div>
  );
};
