import React from 'react';
import { useTranslation } from 'react-i18next';

import { cookiesConfig } from '../footerConfig';

import s from './styles.module.scss';

export const ClientSection = () => {
  const { t } = useTranslation();

  const mapCoockies = cookiesConfig.map(item => {
    return (
      <li key={item.title} className={s.listItem}>
        <a className={s.listItemLink} href={item.link} target="_blank">
          {t(item.title)}
        </a>
      </li>
    );
  });

  return (
    <div className={s.clientSection}>
      <div className={s.container}>
        <div className={s.rights}>
          <span className={s.rightsLabel}>
            {t('gmt_footer_var_3_all_right_reserved')}
          </span>
        </div>
        <ul className={s.list}>{mapCoockies}</ul>
      </div>
    </div>
  );
};
