import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Image } from 'assets/images/brand-icon.svg';
import book from 'constants/book';
import { loadingStart, loadingStop } from 'redux/store/loading/loadingActions';

import { globalLoaderTypes } from '../../Loaders';
import { InfoSection } from './InfoSection';
import { PlayersSection } from './PlayersSection';

import s from './styles.module.scss';

export const TeamCard = props => {
  const { name = '', players = [], urlByName = '' } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClickCard = () => {
    dispatch(loadingStart(globalLoaderTypes.primary));
    setTimeout(() => {
      dispatch(loadingStop());
      history.push(`${book.teams}/${urlByName}`);
    }, 650);
  };

  return (
    <div onClick={handleClickCard} className={s.teamCard}>
      <div className={s.content}>
        <span className={s.icon}>
          <Image className={s.iconImage} />
        </span>
        <InfoSection name={name} />
        <PlayersSection players={players} />
      </div>
    </div>
  );
};
