import { get } from '../apiService';

export const getRosterPlayers = async (limit, tab) => {
  const limitInfo = limit ? '?limit=' + limit : '?limit=100';
  let selectedTab = '&tab=';

  if (tab) selectedTab = '&tab=' + tab;

  return get('/player/page/roster' + limitInfo + selectedTab);
};

export const getPlayers = async (offset, limit, tag) => {
  const activeInfo = offset ? '?offset=' + offset : '?offset=0';
  const limitInfo = limit ? '&limit=' + limit : '&limit=6';
  let selectedTag = '&games=';

  if (tag && tag.id !== '01') selectedTag = '&games=' + tag.id;

  return get('/player/' + activeInfo + limitInfo + selectedTag);
};

export const getPlayerById = id => {
  return get(`/player/${id}/`);
};

export const getPlayerByName = name => {
  return get(`/player/name-url?urlByName=${name}`);
};
