import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import cl from 'classnames';
import book from 'constants/book';
import parse from 'html-react-parser';
import moment from 'moment';
import { loadingStart, loadingStop } from 'redux/store/loading/loadingActions';
import { globalLoaderTypes } from 'views/component/Loaders/GlobalLoader/GlobalLoader.config';

import s from './styles.module.scss';

export const NewsCard = props => {
  const { body, title, dateCreate, urlByName, image, type = 'card' } = props;

  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const getDate = () => {
    const day = moment(dateCreate).format('DD');
    const month = t(
      `characteristics-month_${moment(dateCreate).format('MMM')}`,
    );
    const year = moment(dateCreate).format('YYYY');

    return `${day} ${month}, ${year}`;
  };

  const handleClickReadMore = () => {
    dispatch(loadingStart(globalLoaderTypes.primary));
    setTimeout(() => {
      dispatch(loadingStop());
      history.push(`${book.news}/${urlByName}`);
    }, 650);
  };

  const classes = cl(s.newsItem, {
    [s.newsItemCard]: type === 'card',
    [s.newsItemArticle]: type === 'article',
  });

  return (
    <div className={classes}>
      <div className={s.newsItemHeader}>
        <p className={s.newsItemHeaderTitle}>{title}</p>
      </div>
      {image && type === 'article' && (
        <div className={s.newsItemImageBox}>
          <img src={image} alt="poster" className={s.newsItemImage} />
        </div>
      )}
      <div className={s.newsItemBody}>
        <div className={s.newsItemBodyText}>{body && parse(body)}</div>
      </div>
      <div className={s.newsItemFooter}>
        <span className={s.newsItemFooterDate}>{getDate()}</span>
        {type === 'card' && (
          <button
            className={s.newsItemFooterButton}
            onClick={handleClickReadMore}
          >
            Read More {'>'}
          </button>
        )}
      </div>
    </div>
  );
};
