import React, { useCallback, useState } from 'react';
import Slider from 'react-slick';

import DefaultImg from 'assets/images/default-background-with-logo.png';
import dummyImage from 'assets/images/lazy-image-min.png';
import viewsImage from 'assets/images/new-views.svg';
import cn from 'classnames';
import { mediaSliderWidth } from 'constants/media-slider-width';
import moment from 'moment';
import { abbreviateNumber, useWindowWidth } from 'utilites';

import { settings } from '../settings';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './styles.scss';

export const SliderContent = props => {
  const {
    handleScrollToActiveContent,
    handleScrollToAllContent,
    handleCloseContent,
    setOpenContent,
    adaptiveSlides,
    slidesToShow,
    listOfItems,
    openContent,
    type,
    contentType,
  } = props;

  const [swiped, setSwiped] = useState(false);
  const width = useWindowWidth();

  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleOnItemClick = useCallback(
    e => {
      if (swiped) {
        e.stopPropagation();
        e.preventDefault();
        setSwiped(false);
      }
    },
    [swiped],
  );

  const detectErrorImage = e => {
    if (Number(e.target.height) === 230) {
      e.currentTarget.src = DefaultImg;
    }
  };

  const handleOpenContent = (url, title, id) => {
    if (openContent.status === false || openContent.url !== url) {
      setOpenContent(
        {
          status: true,
          url,
          title,
          type: contentType || type,
        },
        () => (width > mediaSliderWidth ? handleScrollToActiveContent() : {}),
      );
    } else if (openContent.status === true && openContent.url === url) {
      handleCloseContent(handleScrollToAllContent());
    }
  };

  const isShowVideo = type === 'video' && listOfItems.count;

  const isShowStream = type === 'stream' && listOfItems.count;

  return (
    <Slider {...settings(slidesToShow, adaptiveSlides)} onSwipe={handleSwiped}>
      {isShowVideo &&
        listOfItems.items.map(({ titleImage, _id, dateCreate, title, url }) => (
          <div
            className={cn('gmt-home__videos-container-item', {
              active: openContent.url === url,
            })}
            onClick={() => handleOpenContent(url, title)}
            onClickCapture={handleOnItemClick}
            key={_id}
          >
            <div className="gmt-home__videos-container-item-img-wrapper">
              <img
                className="gmt-home__videos-container-item-img"
                src={titleImage || dummyImage}
                alt="video"
                onLoad={detectErrorImage}
              />
            </div>
            <div className="gmt-home__videos-container-item__content">
              <span className="gmt-home__videos-container-item__content-title">
                {title}
              </span>
              <span className="gmt-home__videos-container-item__content-time">
                {moment(dateCreate).fromNow()}
              </span>
            </div>
          </div>
        ))}

      {isShowStream &&
        listOfItems.items.map(
          ({ thumbnail, _id, viewerCount, userLogin, tags, link }) => (
            <div
              className={cn('gmt-home__streams-container-item', {
                active: openContent.url === link,
              })}
              onClick={() => handleOpenContent(link, userLogin, _id)}
              onClickCapture={handleOnItemClick}
              key={_id}
            >
              <img
                src={thumbnail || dummyImage}
                alt="stream"
                className="gmt-home__streams-container-item-img"
              />
              <div className="gmt-home__streams-container-item__content">
                <div className="gmt-home__streams-container-item__content-left">
                  <span className="gmt-home__streams-container-item__content-left__title">
                    {userLogin}
                  </span>

                  {tags && Boolean(tags.length) && (
                    <span className="gmt-home__streams-container-item__content-left__subtitle">
                      {tags[0]?.name}
                    </span>
                  )}
                </div>
                <div className="gmt-home__streams-container-item__content-right">
                  <img
                    className="gmt-home__streams-container-item__content-right-img"
                    src={viewsImage}
                    alt="views"
                  />
                  <span className="gmt-home__streams-container-item__content-right-count">
                    {viewerCount && abbreviateNumber(viewerCount)}
                  </span>
                </div>
              </div>
            </div>
          ),
        )}
    </Slider>
  );
};
