import React from 'react';

import eventsAnimation from 'animation/titlesLoader/events.json';
import { LottieAnimation } from 'views/component';

import s from './styles.module.scss';

export const Header = () => {
  return (
    <div className={s.eventsHeader}>
      <div className={s.title}>
        <LottieAnimation lotti={eventsAnimation} />
      </div>
    </div>
  );
};
