import React from 'react';

import { usePagination } from 'utilites';
import { VenueCard } from 'views/component';
import { Pagination } from 'views/component/Pagination';

import { agencyCards } from '../agency-cards';

import s from './styles.module.scss';

let PageSize = 4;

export const Cards = () => {
  const { currentPage, onPageChange, paginationData } = usePagination({
    pageSize: PageSize,
    data: agencyCards,
  });

  const mapCards = paginationData.map(venue => (
    <div key={venue.key} className={s.cardWrapper}>
      <VenueCard {...venue} />
    </div>
  ));

  return (
    <div className={s.container}>
      {mapCards}

      <div className={s.paginationContainer}>
        <Pagination
          currentPage={currentPage}
          totalCount={agencyCards.length}
          pageSize={PageSize}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};
