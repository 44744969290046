import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import active from 'assets/images/active-arrow.svg';
import disabled from 'assets/images/disabled-arrow.svg';
import book from 'constants/book';
import { loadingStart, loadingStop } from 'redux/store/loading/loadingActions';

import { useInterval } from '../../../utilites';
import { globalLoaderTypes } from '../Loaders';

import './styles.scss';

export const Banner = ({ children }) => {
  const [state, setState] = useState({ index: 0 });
  const { index } = state;
  const dispatch = useDispatch();
  const history = useHistory();

  useInterval(() => {
    if (index + 1 < children.length) {
      setState({ index: index + 1 });
    } else {
      setState({ index: 0 });
    }
  }, 10000);

  const dotClicked = ({
    target: {
      dataset: { index },
    },
  }) => {
    setState({ index: parseInt(index, 10) });
  };

  return (
    <div
      style={{ cursor: children.length && 'pointer' }}
      className="new-slider"
    >
      <div
        className="new-slider__container"
        style={{ left: -'100' * index + '%' }}
      >
        {children.map((child, index) => (
          <div
            className="new-slider__item"
            onClick={() => {
              dispatch(loadingStart(globalLoaderTypes.primary));
              setTimeout(() => {
                dispatch(loadingStop());
                history.push(`${book.news}/${child.key}`);
              }, 650);
            }}
            key={index}
          >
            {child}
          </div>
        ))}
      </div>
      <div>
        {children.length ? (
          <span className="new-slider__count">
            {index + 1}/{children.length}
          </span>
        ) : (
          ''
        )}
      </div>
      <div className="new-slider__dots">
        {children.map((_, index) => (
          <div key={index}>
            {index === state.index ? (
              <div className="active">
                <img
                  alt="arrow"
                  data-index={index}
                  src={active}
                  onClick={dotClicked}
                />
              </div>
            ) : (
              <img
                alt="arrow"
                data-index={index}
                src={disabled}
                onClick={dotClicked}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
