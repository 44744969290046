import { combineReducers } from 'redux';

import { sessionTypes } from './sessionTypes';

const token = (state = null, { type, payload }) => {
  switch (type) {
    case sessionTypes.LOGIN_SUCCESS_CONFIRM:
    case sessionTypes.SIGNUP_SUCCESS_CONFIRM:
    case sessionTypes.CONFIRM_SUCCESS:
    case sessionTypes.GET_CURRENT_SUCCESS_CONFIRM:
      return payload;
    case sessionTypes.LOGOUT_SUCCESS:
      return state;
    default:
      return state;
  }
};

const userId = (state = null, { type, payload }) => {
  switch (type) {
    case sessionTypes.SAVE_USER_ID:
      return payload;
    case sessionTypes.LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
};

const authenticated = (state = false, { type }) => {
  switch (type) {
    case sessionTypes.LOGIN_SUCCESS:
    case sessionTypes.SIGNUP_SUCCESS:
    case sessionTypes.GET_CURRENT_SUCCESS:
    case sessionTypes.LOGIN_SUCCESS_CONFIRM:
    case sessionTypes.SIGNUP_SUCCESS_CONFIRM:
    case sessionTypes.CONFIRM_SUCCESS:
    case sessionTypes.GET_CURRENT_SUCCESS_CONFIRM:
      return true;
    case sessionTypes.LOGOUT_SUCCESS:
      return false;
    default:
      return state;
  }
};
const confirmed = (state = false, { type }) => {
  switch (type) {
    case sessionTypes.LOGIN_SUCCESS_CONFIRM:
    case sessionTypes.SIGNUP_SUCCESS_CONFIRM:
    case sessionTypes.CONFIRM_SUCCESS:
    case sessionTypes.GET_CURRENT_SUCCESS_CONFIRM:
      return true;
    case sessionTypes.LOGOUT_SUCCESS:
      return false;
    default:
      return state;
  }
};

const error = (state = null, { type, payload }) => {
  switch (type) {
    case sessionTypes.LOGOUT_ERROR:
    case sessionTypes.SIGNUP_ERROR:
    case sessionTypes.LOGIN_ERROR:
    case sessionTypes.CONFIRM_ERROR:
    case sessionTypes.GET_CURRENT_ERROR:
    case sessionTypes.RE_SEND_CONFIRM_CODE_ERROR:
      return payload;
    default:
      return state;
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case sessionTypes.SIGNUP_REQUEST:
    case sessionTypes.LOGIN_REQUEST:
    case sessionTypes.FORGOT_REQUEST:
    case sessionTypes.RESET_REQUEST:
    case sessionTypes.CONFIRM_REQUEST:
    case sessionTypes.RE_SEND_CONFIRM_CODE_REQUEST:
      return true;
    case sessionTypes.SIGNUP_SUCCESS:
    case sessionTypes.SIGNUP_SUCCESS_CONFIRM:
    case sessionTypes.SIGNUP_ERROR:
    case sessionTypes.LOGIN_SUCCESS:
    case sessionTypes.LOGIN_SUCCESS_CONFIRM:
    case sessionTypes.LOGIN_ERROR:
    case sessionTypes.FORGOT_SUCCESS:
    case sessionTypes.FORGOT_ERROR:
    case sessionTypes.RESET_SUCCESS:
    case sessionTypes.RESET_ERROR:
    case sessionTypes.CONFIRM_SUCCESS:
    case sessionTypes.CONFIRM_ERROR:
    case sessionTypes.RE_SEND_CONFIRM_CODE_SUCCESS:
    case sessionTypes.RE_SEND_CONFIRM_CODE_ERROR:
      return false;
    default:
      return state;
  }
};

export const sessionReducer = combineReducers({
  authenticated,
  confirmed,
  token,
  userId,
  error,
  loading,
});
