import types from './profileTypes';

export const setProfileData = (data = {}) => ({
  type: types.SET_PROFILE_DATA,
  payload: data,
});

export const setProfileAvatar = (data = {}) => ({
  type: types.SET_PROFILE_AVATAR,
  payload: data,
});

export const putProfileRequest = () => ({
  type: types.PUT_PROFILE_REQUEST,
});

export const putProfileSuccess = payload => ({
  type: types.PUT_PROFILE_SUCCESS,
  payload,
});

export const putProfileError = (error = '') => ({
  type: types.PUT_PROFILE_ERROR,
  payload: error,
});

export const getProfileRequest = () => ({
  type: types.GET_PROFILE_REQUEST,
});

export const getProfileSuccess = (data = {}) => ({
  type: types.GET_PROFILE_SUCCESS,
  payload: data,
});

export const getProfileError = (error = '') => ({
  type: types.GET_PROFILE_ERROR,
  payload: error,
});

export const clearProfileAfterLogout = () => ({
  type: types.CLEAR_PROFILE_PAGE,
});
