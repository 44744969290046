import * as api from '../../../api/team-service/team-service';
import { globalLoaderTypes } from '../../../views/component/Loaders/GlobalLoader/GlobalLoader.config';
import { loadingStart, loadingStop } from '../loading/loadingActions';
import * as teamsActions from './teamsActions';

export const fetchTeamsList =
  (startLoading, offset, limit, isOpponent) => dispatch => {
    dispatch(teamsActions.getTeamsRequest());
    if (startLoading) dispatch(loadingStart(globalLoaderTypes.secondary));

    return api
      .getTeams(offset, limit, isOpponent)
      .then(response => dispatch(teamsActions.getTeamsSuccess(response)))
      .catch(error => dispatch(teamsActions.getTeamsError(error)))
      .finally(() => {
        if (startLoading) {
          dispatch(loadingStart(globalLoaderTypes.tertiary));
          setTimeout(() => dispatch(loadingStop()), 650);
        }
      });
  };

export const fetchTeamById = id => dispatch => {
  dispatch(teamsActions.getTeamByIdRequest());
  dispatch(loadingStart(globalLoaderTypes.secondary));

  return api
    .getTeamById(id)
    .then(response => dispatch(teamsActions.getTeamByIdSuccess(response)))
    .catch(error => dispatch(teamsActions.getTeamByIdError(error)))
    .finally(() => {
      dispatch(loadingStart(globalLoaderTypes.tertiary));
      setTimeout(() => dispatch(loadingStop()), 650);
    });
};

export const fetchTeamByName = name => dispatch => {
  dispatch(teamsActions.getTeamByNameRequest());
  dispatch(loadingStart(globalLoaderTypes.secondary));

  return api
    .getTeamByName(name)
    .then(response => dispatch(teamsActions.getTeamByNameSuccess(response)))
    .catch(error => dispatch(teamsActions.getTeamByNameError(error)))
    .finally(() => {
      dispatch(loadingStart(globalLoaderTypes.tertiary));
      setTimeout(() => dispatch(loadingStop()), 650);
    });
};
