import types from './tournamentsTypes';

const initialState = {
  allTournaments: { items: [], count: 0 },
  tournament: {
    background: '',
    description: {},
    endDate: '',
    startDate: '',
    logo: '',
    playersAvatar: [],
    title: '',
  },
  loader: true,
  error: '',
};

export const tournamentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_TOURNAMENTS_SUCCESS:
      return { ...state, allTournaments: payload, loader: false };

    case types.GET_TOURNAMENT_BY_ID_SUCCESS:
      return { ...state, tournament: payload, loader: false };

    case types.GET_TOURNAMENT_BY_ID_ERROR:
    case types.GET_TOURNAMENTS_ERROR:
      return { ...state, error: payload, loader: false };

    case types.GET_TOURNAMENT_BY_ID_REQUEST:
    case types.GET_TOURNAMENTS_REQUEST:
      return { ...state, loader: true };

    default:
      return state;
  }
};
