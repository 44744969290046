import React, { Component } from 'react';

import ErrorPage from '../ErrorPage/ErrorPage';

class ErrorBoundary extends Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { errorInfo } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      return <ErrorPage />;
    }

    return children;
  }
}

export default ErrorBoundary;
