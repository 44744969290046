import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Icons
import logo from 'assets/images/new-logo.svg';
import cn from 'classnames';
// Constants
import book from 'constants/book';
// Models
import { LINK_MODEL } from 'models/link.model';
// Store
import { loadingStart, loadingStop } from 'redux/store/loading/loadingActions';
import { globalLoaderTypes } from 'views/component/Loaders/GlobalLoader/GlobalLoader.config';

// Components
import NavItem from './NavItem/NavItem';

import './styles.scss';

const LandingHeader = ({ className, resizeHeader, navItems, shopSubmenu }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRedirect = (e, ref) => {
    e.preventDefault();
    dispatch(loadingStart(globalLoaderTypes.primary));
    setTimeout(() => {
      dispatch(loadingStop());
      history.push(ref);
      resizeHeader();
    }, 650);
  };

  const onClickLogo = () => {
    dispatch(loadingStart(globalLoaderTypes.primary));
    setTimeout(() => {
      dispatch(loadingStop());
      history.push(book.landing);
      resizeHeader();
    }, 650);
  };

  return (
    <div className={cn('gmt-header', className)}>
      <ul className="gmt-header__navigation">
        <li onClick={onClickLogo} className="gmt-header__navigation-item">
          <img className="gmt-header__navigation-logo" src={logo} alt="logo" />
        </li>

        {navItems.map(({ title, ref, isRedirect, isWithSubmenu }) => (
          <li className="gmt-header__navigation-item" key={title}>
            <NavItem
              title={title}
              href={ref}
              isRedirect={isRedirect}
              onClick={handleRedirect}
            />
            {Boolean(isWithSubmenu && shopSubmenu?.length) && (
              <div className="gmt-header__navigation-submenu">
                <div className="gmt-header__navigation-submenu-inner">
                  {shopSubmenu.map(submenuItem => (
                    <a
                      key={submenuItem[LINK_MODEL.id]}
                      href={submenuItem[LINK_MODEL.url]}
                      className="gmt-header__navigation-submenu-link"
                    >
                      {submenuItem[LINK_MODEL.name]}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LandingHeader;
