import { get, post, remove } from '../apiService';

export function getVideoList(offset, limit, tag, type, lng, player) {
  const activeInfo = offset ? '?offset=' + offset : '?offset=0';
  const limitInfo = limit ? '&limit=' + limit : '&limit=6';
  let selectedTag = '&tags=';
  const siteLanguage = '&lang=' + lng;
  const playerInfo = player ? '&players=' + player : '';

  if (tag && tag.id !== '01') selectedTag = '&tags=' + tag.id;

  return get(
    '/video/' +
      activeInfo +
      limitInfo +
      selectedTag +
      '&kind=' +
      type +
      siteLanguage +
      playerInfo,
  );
}

export function getVideo(id) {
  return get('/video/' + id);
}

export function postComment(id, body) {
  return post(`/video/${id}/comment`, body);
}

export function getAlbumList(active, limit, tag) {
  const activeInfo = active ? '?offset=' + active : '?offset=0';
  const limitInfo = limit ? '&limit=' + limit : '&limit=5';
  let selectedTag = '&tags=';
  if (tag && tag.id !== '01') selectedTag = '&tags=' + tag.id;

  return get('/album/' + activeInfo + limitInfo + selectedTag);
}

export function getAlbumById(id) {
  return get('/album/' + id);
}

export function getPhotoById(idPhoto) {
  return get('/photo/' + idPhoto);
}

export const postAlbumItemLike = id => {
  return post(`/photo/${id}/like/`);
};

export const postAlbumItemDislike = id => {
  return post(`/photo/${id}/dislike/`);
};

export const postAlbumItemComment = (id, body) => {
  return post(`/photo/${id}/comment/`, body);
};

export const deletePhotoComment = (newsId, commentId) => {
  return remove(`/photo/${newsId}/comment/${commentId}`);
};

export const postAlbumItemCommentLike = id => {
  return post(`/comment/${id}/like/`);
};

export const postAlbumItemCommentDislike = id => {
  return post(`/comment/${id}/dislike/`);
};

export function commentVideoLike(id) {
  return post(`/comment/${id}/like/`);
}

export function commentVideoDislike(id) {
  return post(`/comment/${id}/dislike/`);
}

export function videoLike(id) {
  return post(`/video/${id}/like/`);
}

export function videoDislike(id) {
  return post(`/video/${id}/dislike/`);
}

export const deleteVideoComment = (newsId, commentId) => {
  return remove(`/video/${newsId}/comment/${commentId}`);
};
