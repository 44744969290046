import book from 'constants/book';
import AboutUs from 'views/pages/aboutUs/AboutUs';
import Agency from 'views/pages/agency/Agency';
import Events from 'views/pages/events/Events';
import Landing from 'views/pages/landing/Landing';
import News from 'views/pages/news/News';
import NewsItem from 'views/pages/newsItem/NewsItem';
import Partners from 'views/pages/partners/Partners';
import Roster from 'views/pages/roster/Roster';
import RosterItem from 'views/pages/rosterItem/RoasterItem';
import Teams from 'views/pages/teams/Teams';
import TeamsItem from 'views/pages/teamsItem/TeamsItem';
import Venues from 'views/pages/venues/Venues';

export const publicRoutes = [
  {
    path: `${book.landing}`,
    component: Landing,
    isExact: true,
  },
  {
    path: `${book.roster}`,
    component: Roster,
    isExact: true,
  },
  {
    path: `${book.rosterItem}`,
    component: RosterItem,
  },
  {
    path: `${book.teams}`,
    component: Teams,
    isExact: true,
  },
  {
    path: `${book.teamsItem}`,
    component: TeamsItem,
  },
  {
    path: `${book.news}`,
    component: News,
    isExact: true,
  },
  {
    path: `${book.newsItem}`,
    component: NewsItem,
  },
  {
    path: `${book.partners}`,
    component: Partners,
    isExact: true,
  },
  {
    path: `${book.aboutUs}`,
    component: AboutUs,
    isExact: true,
  },
  {
    path: `${book.events}`,
    component: Events,
    isExact: true,
  },
  {
    path: `${book.venues}`,
    component: Venues,
    isExact: true,
  },

  {
    path: `${book.agency}`,
    component: Agency,
    isExact: true,
  },
];

export const privateRoutes = [];
