import primaryLoader from '../../../../animation/statusesLoader/primary.json';
import secondaryLoader from '../../../../animation/statusesLoader/secondary.json';
import tertiaryLoader from '../../../../animation/statusesLoader/tertiary.json';

export const globalLoaderTypes = {
  secondary: 'secondary',
  tertiary: 'tertiary',
  primary: 'primary',
};

export const globalLoaderConfig = {
  [globalLoaderTypes.secondary]: secondaryLoader,
  [globalLoaderTypes.tertiary]: tertiaryLoader,
  [globalLoaderTypes.primary]: primaryLoader,
};
