import * as api from '../../../api/players-service/players-service';
import { globalLoaderTypes } from '../../../views/component/Loaders/GlobalLoader/GlobalLoader.config';
import { loadingStart, loadingStop } from '../loading/loadingActions';
import * as playersActions from './playersActions';

export const fetchPlayersList = (offset, limit, tag) => dispatch => {
  dispatch(playersActions.getPlayersRequest());
  // dispatch(loadingStart());

  return api
    .getPlayers(offset, limit, tag)
    .then(items => dispatch(playersActions.getPlayersSuccess(items)))
    .catch(error => dispatch(playersActions.getPlayersError(error)));
};

export const fetchRosterPlayersList =
  (limit, tab, startLoading) => dispatch => {
    dispatch(playersActions.getRosterPlayersRequest());
    if (startLoading) dispatch(loadingStart(globalLoaderTypes.secondary));

    return api
      .getRosterPlayers(limit, tab)
      .then(items => dispatch(playersActions.getRosterPlayersSuccess(items)))
      .catch(error => dispatch(playersActions.getRosterPlayersError(error)))
      .finally(() => {
        if (startLoading) {
          dispatch(loadingStart(globalLoaderTypes.tertiary));
          setTimeout(() => dispatch(loadingStop()), 650);
        }
      });
  };

export const fetchPlayerById = id => dispatch => {
  dispatch(playersActions.getPlayerByIdRequest());
  dispatch(loadingStart(globalLoaderTypes.secondary));
  return api
    .getPlayerById(id)
    .then(item => dispatch(playersActions.getPlayerByIdSuccess(item)))
    .catch(error => dispatch(playersActions.getPlayerByIdError(error)))
    .finally(() => {
      dispatch(loadingStart(globalLoaderTypes.tertiary));
      setTimeout(() => dispatch(loadingStop()), 650);
    });
};

export const fetchPlayerByName = name => dispatch => {
  dispatch(playersActions.getPlayerByIdRequest());
  dispatch(loadingStart(globalLoaderTypes.secondary));
  return api
    .getPlayerByName(name)
    .then(item => dispatch(playersActions.getPlayerByNameSuccess(item)))
    .catch(error => dispatch(playersActions.getPlayerByNameError(error)))
    .finally(() => {
      dispatch(loadingStart(globalLoaderTypes.tertiary));
      setTimeout(() => dispatch(loadingStop()), 650);
    });
};

export const clearPageDataPlayerItem = () => dispatch =>
  dispatch(playersActions.clearPageDataPlayerItem());
