import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { typeOfPlayers } from 'constants/typeOfPlayers';
import { SharedClassProvider, useSharedClassContext } from 'contexts';
import { fetchRosterPlayersList } from 'redux/store/players/playerOperations';

import { Cards } from './Cards';
import { Header } from './Header';
import { Tabs } from './Tabs';

import './styles.scss';

const RosterComponent = () => {
  const dispatch = useDispatch();
  const { rootClass, appendClass } = useSharedClassContext();

  const [activeTab, setActiveTab] = useState(typeOfPlayers[0].value);

  useEffect(() => {
    dispatch(fetchRosterPlayersList(100, activeTab, true));
  }, []);

  return (
    <div className={rootClass}>
      <div className={appendClass('__header-container')}>
        <Header />
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className={appendClass('__body-container')}>
        <Cards />
      </div>
    </div>
  );
};

const Roster = () => {
  return (
    <SharedClassProvider rootPrefix="roster">
      <RosterComponent />
    </SharedClassProvider>
  );
};

export default Roster;
