import { types } from './partnersTypes';

export const getPartnersRequest = () => ({
  type: types.GET_PARTNERS_REQUEST,
});

export const getPartnersSuccess = (data = {}) => ({
  type: types.GET_PARTNERS_SUCCESS,
  payload: data,
});

export const getPartnersError = (error = '') => ({
  type: types.GET_PARTNERS_ERROR,
  payload: error,
});
