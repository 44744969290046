import React from 'react';
import { useDispatch } from 'react-redux';

import cl from 'classnames';

import { typeOfPlayers } from '../../../../constants/typeOfPlayers';
import { useSharedClassContext } from '../../../../contexts';
import { fetchRosterPlayersList } from '../../../../redux/store/players/playerOperations';

import './styles.scss';

export const Tabs = props => {
  const { activeTab, setActiveTab } = props;

  const dispatch = useDispatch();

  const { appendClass } = useSharedClassContext();

  const handleClickTab = value => {
    setActiveTab(value);
    dispatch(fetchRosterPlayersList(100, value, false));
  };

  const mapTabs = typeOfPlayers.map(tab => {
    const { value, title } = tab;

    const isActive = activeTab === value;
    const tabClasses = cl(appendClass('__tabs-item'), {
      [appendClass('__tabs-item--active')]: isActive,
      [appendClass('__tabs-item--inactive')]: !isActive,
    });

    return (
      <div
        onClick={() => handleClickTab(value)}
        className={tabClasses}
        key={title}
      >
        <span className={appendClass('__tabs-item-text')} data-letters={title}>
          {title}
        </span>
      </div>
    );
  });

  return (
    <div className={appendClass('__tabs')}>
      <div className={appendClass('__tabs-container')}>{mapTabs}</div>
    </div>
  );
};
