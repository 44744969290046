import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from 'redux/store/store';

import Navigation from '../router/router';

import './styles.scss';
import 'assets/styles/globalStyles.scss';
import 'assets/styles/container.scss';
import 'assets/styles/normalize.scss';
import 'assets/styles/_mixin.scss';
import 'assets/styles/_fonts.scss';
import 'assets/styles/base.scss';

const Main = () => {
  useEffect(() => {
    return () => clearTimeout(window.updateTokenTimeoutId);
  });

  return (
    <div className="main">
      <Provider store={store}>
        <Router>
          <Navigation />
        </Router>
      </Provider>
    </div>
  );
};

export default Main;
