const types = {
  INIT_ALL_EVENT_LIST: 'calendar-event/INIT_ALL_EVENT_LIST',
  SET_ACTIVE_EVENT_LIST: 'calendar-event/SET_ACTIVE_EVENT_LIST',
  SET_ACTIVE_CATEGORY: 'calendar-event/SET_ACTIVE_CATEGORY',
  INFO_EVENTS_SHOW: 'calendar-event/INFO_EVENTS_SHOW',
  INFO_ALL_EVENTS: 'calendar-event/INFO_ALL_EVENTS',
  CLOSE_INFO_EVENT: 'calendar-event/CLOSE_INFO_EVENT',
  ON_LOADER: 'calendar-event/ON_LOADER',
  OFF_LOADER: 'calendar-event/OFF_LOADER',
};
export default types;
