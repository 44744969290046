export const settings = (slidesToShow, adaptiveSlides) => {
  return {
    className: 'custom-slider',
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    initialSlide: 0,
    slidesToShow,
    arrows: false,
    swipeToSlide: true,
    centerMode: true,
    dots: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: adaptiveSlides,
        },
      },
      {
        breakpoint: 650,
        settings: {
          speed: 100,
          slidesToShow: adaptiveSlides,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
};
