import { get, post, remove } from '../apiService';

export const getGameList = (data, discipline) => {
  const { minDay, maxDay, offset, limit, type } = data;
  let selectedTag = '&discipline=';
  if (discipline && discipline.id !== '01')
    selectedTag = '&discipline=' + discipline.id;
  let url = '';

  if (type === 'calendarPagination') {
    const minData = '?dateFrom=' + (minDay ? new Date(minDay) : '');
    const maxData = '&dateTo=' + (maxDay ? new Date(maxDay) : '');

    url = '/game/' + minData + maxData + selectedTag + '&limit=1000';
  } else {
    const activeInfo = offset ? '?offset=' + offset : '?offset=0';
    const limitInfo = limit ? '&limit=' + limit : '&limit=12';
    url = '/game/' + activeInfo + limitInfo + selectedTag;
  }
  if (url) return get(url);
};

export const getGameById = id => {
  return get(`/game/${id}/`);
};

export const postGameItemLike = id => {
  return post(`/game/${id}/like/`);
};

export const postGameItemDislike = id => {
  return post(`/game/${id}/dislike/`);
};

export const postGameItemComment = (id, body) => {
  return post(`/game/${id}/comment/`, body);
};

export const postGameItemCommentLike = id => {
  return post(`/comment/${id}/like/`);
};

export const postGameItemCommentDislike = id => {
  return post(`/comment/${id}/dislike/`);
};

export const deleteGameItemComment = (gameId, commentId) => {
  return remove(`/game/${gameId}/comment/${commentId}`);
};

export const getTournamentList = (data, discipline) => {
  const { minDay, maxDay, offset, limit, type } = data;
  let selectedTag = '&discipline=';
  if (discipline && discipline.id !== '01')
    selectedTag = '&discipline=' + discipline.id;
  let url = '';

  if (type === 'calendarPagination') {
    const minData = '?dateFrom=' + (minDay ? new Date(minDay) : '');
    const maxData = '&dateTo=' + (maxDay ? new Date(maxDay) : '');

    url = '/tournament/' + minData + maxData + selectedTag + '&limit=1000';
  } else {
    const activeInfo = offset ? '?offset=' + offset : '?offset=0';
    const limitInfo = limit ? '&limit=' + limit : '&limit=12';
    url =
      '/tournament/' +
      activeInfo +
      limitInfo +
      '&active=true' +
      '&sortBy=date' +
      '&orderBy=asc' +
      selectedTag;
  }
  if (url) return get(url);
};

export const getTournamentById = id => {
  return get(`/tournament/${id}`);
};
