import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressiveImage } from 'views/component';

import s from './styles.module.scss';

export const Header = memo(props => {
  const { players, name } = props;

  const { t } = useTranslation();

  const mapPlayers =
    Array.isArray(players) && players.length
      ? players.map((player, idx) => {
          const options = {
            className: s.playersItemImage,
          };
          return (
            <span
              key={player._id}
              className={s.playersItem}
              style={{
                '--item-number': idx,
              }}
            >
              <ProgressiveImage
                image={player.avatar}
                alt="player"
                options={options}
              />
            </span>
          );
        })
      : null;

  return (
    <div className={s.header}>
      <div className={s.info}>
        <h5 className={s.infoTitle}>{t('team_title')}</h5>
        <h2 className={s.infoName}>{name}</h2>
      </div>
      <div className={s.players}>{mapPlayers}</div>
    </div>
  );
});
