import * as apiLive from '../../../api/live-service/live-service';
import * as liveActions from './liveActions';

export const fetchLiveStream = (offset, limit) => dispatch => {
  dispatch(liveActions.getLiveRequest());

  return apiLive
    .getLive(offset, limit)
    .then(response => {
      dispatch(liveActions.getLiveSuccess(response));
    })
    .catch(error => {
      liveActions.getLiveError(error);
    });
};
