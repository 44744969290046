export const partnersListSelector = state => state.partners.allPartners;
export const partnersLoaderSelector = state => state.partners.loader;
export const bannersListSelector = state => state.banner;

//session
export const sessionTokenSelector = state => state.session.token;
export const sessionAuthenticatedSelector = state =>
  state.session.authenticated;
export const sessionConfirmedSelector = state => state.session.confirmed;
export const sessionLoadingSelector = state => state.session.loading;
export const sessionUserIdSelector = state => state.session.userId;

export const newsSelector = state => state.news.allNews;
export const newsLoaderSelector = state => state.news.loader;
export const streamSelector = state => state.stream.allStream;
//
export const videoSelector = state => state.video.allVideo;
export const videoLoaderSelector = state => state.video.loader;

export const newsItemSelector = state => state.news.newsItem;
export const newsItemCommentsSelector = state => state.news.newsItem.comments;

export const playerItemSelector = state => state.players.playerItem;
export const playersSelector = state => state.players.allPlayers;
export const rosterPlayersSelector = state => state.players.rosterPlayers;
export const playersLoaderSelector = state => state.players.loader;

export const photoGallerySelector = state => state.photo.data.gallery;
export const photoGalleryLoaderSelector = state => state.photo.data.loader;

export const photoAlbumSelector = state => state.photo.data.album;
export const photoAlbumItemSelector = state => state.photo.data.albumItem;
export const photoLoadingSelector = state => state.photo.loading;
export const globalLoading = state => state.loading;
export const languageSelector = state => state.language;
export const notificationSelector = state => state.notification;
export const profileSelector = state => state.profile;
export const allGameSelector = state => state.games.allGames;
export const allGameLoaderSelector = state => state.games.loader;

export const gameItemInfoSelector = state => state.games.gameItem.information;
export const gameItemPlayersFirstTeamSelector = state =>
  state.games.gameItem.firstTeamPlayers;
export const gameItemPlayersSecondTeamSelector = state =>
  state.games.gameItem.secondTeamPlayers;

export const gameItemCommentsSelector = state =>
  state.games.gameItem.information.comments;

export const videoLoadedSelector = state => state.video.loader;
export const videoInfoSelector = state => state.video.videoInfo;
export const videoCommentsSelector = state => state.video.activeVideo.comments;
export const videoActiveItemSelector = state => state.video.activeVideo;
export const relatedListSelector = state => state.video.relatedList;
export const stuffListSelector = state => state.stuff;
export const achievementsListSelector = state => state.achievements;
export const photoAlbumItemCommentsSelector = state =>
  state.photo.data.albumItem.comments;
export const searchCategorySelector = state => state.search.currentCategory;

export const allTournamentsSelector = state => state.tournaments.allTournaments;
export const tournamentSelector = state => state.tournaments.tournament;
export const allTournamentsLoaderSelector = state => state.tournaments.loader;
export const liveStreamsSelector = state => state.live.liveStreams;
export const liveStreamsLoaderSelector = state => state.live.isLoaded;

export const teamsListSelector = state => state.teams.teamsList;
export const teamByIdSelector = state => state.teams.teamById;
export const teamsListLoaderSelector = state => state.teams.loader;
