import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import './NavItem.styles.scss';

const NavItem = ({ t, title, href, isRedirect, onClick }) => {
  const Link = useMemo(() => {
    if (isRedirect) {
      return 'a';
    }

    return NavLink;
  }, [isRedirect]);

  const to = isRedirect ? { href: href } : { to: href };

  const handleClick = e => {
    if (isRedirect) return;

    onClick(e, href);
  };

  return (
    <Link
      data-letters={t(title)}
      className="gmt-header__navigation-link"
      onClick={handleClick}
      {...to}
    >
      {t(title)}
    </Link>
  );
};

export default withTranslation()(NavItem);
