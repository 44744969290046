import { ReactComponent as DiscordIcon } from 'assets/images/footer/var-3/discord.svg';
import { ReactComponent as FBIcon } from 'assets/images/footer/var-3/fb.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/footer/var-3/instagram.svg';
import { ReactComponent as RedditIcon } from 'assets/images/footer/var-3/reddit.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/footer/var-3/twitter.svg';
import { ReactComponent as VKIcon } from 'assets/images/footer/var-3/vk.svg';
import { ReactComponent as YoutubeIcon } from 'assets/images/footer/var-3/youtube.svg';
import book from 'constants/book';

export const socialConfig = [
  {
    IconComponent: <InstagramIcon />,
    alt: 'instagram icon',
    link: 'https://instagram.com/gmt.gg',
    name: 'Instagram',
  },
  {
    IconComponent: <RedditIcon />,
    alt: 'reddit icon',
    link: 'https://www.reddit.com/r/GMTesports/',
    name: 'Reddit',
  },
  {
    IconComponent: <DiscordIcon />,
    alt: 'discord icon',
    link: 'https://discord.gg/Zt5QJrewYc',
    name: 'Discord',
  },
  {
    IconComponent: <YoutubeIcon />,
    alt: 'youtube icon',
    link: 'https://www.youtube.com/channel/UCWDqd4Equ6GRhdqdQ8kqrig',
    name: 'Youtube',
  },
  {
    IconComponent: <TwitterIcon />,
    alt: 'twitter icon',
    link: 'https://twitter.com/GMTesports',
    name: 'Twitter',
  },

  {
    IconComponent: <VKIcon />,
    alt: 'vk icon',
    link: 'https://vk.com/gmtesports',
    name: 'Vkontakte',
  },
  {
    IconComponent: <FBIcon />,
    alt: 'facebook icon',
    link: 'https://www.facebook.com/gmtesports/?__nodl&ref=external%3Alinktr.ee',
    name: 'Facebook',
  },
];

export const cookiesConfig = [
  {
    title: 'gmt_footer_var_3_cookies_processing',
    link: 'https://www.google.com',
  },
  {
    title: 'gmt_footer_var_3_cookies_control',
    link: 'https://www.google.com',
  },
  {
    title: 'gmt_footer_var_3_p_p',
    link: 'https://www.google.com',
  },
];

export const linksConfig = [
  { ref: book.roster, title: 'gmt_header_roster' },
  { ref: book.teams, title: 'gmt_header_teams' },
  { ref: book.events, title: 'gmt_header_events' },
  {
    ref: 'http://shop.gmt.gg/',
    title: 'gmt_header_shop',
    isRedirect: true,
    isWithSubmenu: true,
  },
  { ref: book.news, title: 'gmt_header_news' },
  { ref: book.aboutUs, title: 'gmt_header_about_us' },
  { ref: book.partners, title: 'gmt_header_partners' },
  { ref: book.venues, title: 'gmt_header_venues' },
  { ref: book.agency, title: 'gmt_header_agency' },
];
