import React from 'react';

import { Description } from './Description';
import { Videos } from './Videos';

const RoasterItem = () => {
  return (
    <div>
      <Description />
      <Videos />
    </div>
  );
};

export default RoasterItem;
