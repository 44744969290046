import types from './liveTypes';

export const getLiveRequest = () => ({
  type: types.GET_LIVE_REQUEST,
});

export const getLiveSuccess = (data = {}) => ({
  type: types.GET_LIVE_SUCCESS,
  payload: data,
});

export const getLiveError = (error = '') => ({
  type: types.GET_LIVE_ERROR,
  payload: error,
});
