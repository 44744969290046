export const typeOfPlayers = [
  {
    title: 'ALL',
    value: '',
  },
  {
    title: 'CREATORS',
    value: 'CREATOR',
  },
  {
    title: 'PROS',
    value: 'PROS',
  },
];
