import { get } from '../apiService';

export const getTeamById = id => {
  return get(`/team/${id}?active=true`);
};

export const getTeams = (offset, limit, isOpponent) => {
  const activeInfo = offset ? '?offset=' + offset : '?offset=0';
  const limitInfo = limit ? '&limit=' + limit : '&limit=6';
  let isOpponentInfo = isOpponent
    ? '&isOpponent=' + isOpponent
    : '&isOpponent=false';

  return get('/team/' + activeInfo + limitInfo + isOpponentInfo);
};

export const getTeamByName = name => {
  return get(`/team/name-url?active=true&urlByName=${name}`);
};
