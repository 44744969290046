import React from 'react';

import s from './styles.module.scss';

export const InfoSection = props => {
  const { name = '' } = props;

  return (
    <div className={s.info}>
      <div className={s.description}>
        <span className={s.descriptionTitle} data-info="">
          TEAM
        </span>
        <span className={s.descriptionSubtitle} data-info="">
          {name}
        </span>
      </div>
      <div className={s.link}>
        <span className={s.linkText} data-info="">
          VIEW PROFILE >
        </span>
      </div>
    </div>
  );
};
