import book from 'constants/book';

export const headerConfig = [
  { ref: book.roster, title: 'gmt_header_roster' },
  { ref: book.teams, title: 'gmt_header_teams' },
  { ref: book.events, title: 'gmt_header_events' },
  {
    ref: 'http://shop.gmt.gg/',
    title: 'gmt_header_shop',
    isRedirect: true,
  },
  { ref: book.news, title: 'gmt_header_news' },
  { ref: book.aboutUs, title: 'gmt_header_about_us' },
  { ref: book.partners, title: 'gmt_header_partners' },
  { ref: book.venues, title: 'gmt_header_venues' },
  { ref: book.agency, title: 'gmt_header_agency' },
];
