import FirstImage from 'assets/images/venues/1.png';
import SecondImage from 'assets/images/venues/2.png';
import ThirdImage from 'assets/images/venues/3.png';
import FourthImage from 'assets/images/venues/4.png';

export const venuesCards = [
  {
    key: '1',
    title: 'Title',
    description:
      'Fusce eget tellus sit amet sem tempor malesuada. Vivamus vel quam venenatis, scelerisque lorem a, maximus lorem. Quisque in tempor massa, vitae egestas purus. Praesent dictum ipsum',
    location:
      'Mimar Hayrettin, Paşa Caddesi & Doğramacı Sokak No:21, 34126 Fatih/İstanbul,',
    images: [
      {
        key: '1',
        image: FirstImage,
      },
      {
        key: '2',
        image: SecondImage,
      },
      {
        key: '3',
        image: ThirdImage,
      },
      {
        key: '4',
        image: FourthImage,
      },
      {
        key: '5',
        image: SecondImage,
      },
    ],
  },
];
