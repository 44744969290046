import React from 'react';

import closeIcon from 'assets/images/remove.svg';

import './styles.scss';

export const ActiveContentSmallSize = props => {
  const { handleScrollToAllContent, handleCloseContent } = props;

  return (
    <div
      onClick={() => handleCloseContent(handleScrollToAllContent())}
      className="gmt-home__active-content big__img"
    >
      <img
        className="gmt-home__active-content big__img-icon"
        src={closeIcon}
        alt="close"
      />
    </div>
  );
};
