import types from './notificationTypes';

export const openNotificationModal = payload => ({
  type: types.OPEN_NOTIFICATION_MODAL,
  payload,
});

export const closeNotificationModal = () => ({
  type: types.CLOSE_NOTIFICATION_MODAL,
});
