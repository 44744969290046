import about from './about.json';
import achievements from './achievements.json';
import authorization from './authorization.json';
import calendar from './calendar.json';
import comment from './comments.json';
import contacts from './contacts.json';
import footer from './footer.json';
import header from './header.json';
import navigation from './navigation.json';
import notification from './notification.json';
import player from './player.json';
import profile from './profile.json';
import rest from './rest.json';
import roster from './roster.json';
import search from './search.json';
import team from './team.json';
import title from './title.json';
import validators from './validators.json';

const translation = {
  ...calendar,
  ...navigation,
  ...title,
  ...authorization,
  ...validators,
  ...profile,
  ...contacts,
  ...comment,
  ...rest,
  ...player,
  ...notification,
  ...search,
  ...achievements,
  ...footer,
  ...header,
  ...roster,
  ...team,
  ...about,
};
export default translation;
