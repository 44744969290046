export const scrollIntoView = (
  element,
  delay = null,
  behavior = 'smooth',
  block = 'start',
) => {
  if (delay) {
    setTimeout(() => {
      element.scrollIntoView({ behavior, block });
    }, delay);
  } else element.scrollIntoView({ behavior, block });
};

export const openPageTop = () => {
  window.scrollTo(0, 0);
};

export const setScrollPosition = (
  { top = 0, left = 0 } = {},
  delay = null,
  behavior = 'smooth',
) => {
  if (delay) {
    setTimeout(() => {
      window.scroll({ top, left, behavior });
    }, delay);
  } else {
    window.scroll({ top, left }, behavior);
  }
};

export const scrollTop = (delay = null, behavior = 'smooth') => {
  if (typeof window === 'undefined') return;

  window.requestAnimationFrame(() => {
    if (delay) {
      setTimeout(() => {
        window.scroll({ top: 0, left: 0, behavior });
      }, delay);
    } else window.scroll({ top: 0, left: 0, behavior });
  });
};

export const scrollBottom = (delay = null, behavior = 'smooth') => {
  if (typeof window === 'undefined') return;

  window.requestAnimationFrame(() => {
    if (delay) {
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, delay);
    } else window.scroll(0, document.body.scrollHeight);
  });
};
