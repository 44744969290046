import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchTournamentsList } from 'redux/store/tournaments/tournamentsOperations';

import { Cards } from './Cards';
import { Header } from './Header';

const Events = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTournamentsList(0, 6, true));
  }, []);

  return (
    <div>
      <Header />
      <Cards />
    </div>
  );
};

export default Events;
