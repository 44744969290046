import types from '../video/videoTypes';

const initialState = {
  allVideo: {
    items: [],
  },
  videoInfo: {},
  relatedList: {},
  activeVideo: {},
  comment: [],
  loader: true,
  error: '',
};

export const videoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_VIDEO_LIST_SUCCESS:
      return { ...state, allVideo: payload, loader: false };
    case types.GET_VIDEO_ITEM_REQUEST:
      return { ...state, activeVideo: payload };
    case types.EDIT_VIDEO_COMMENT:
      return {
        ...state,
        activeVideo: {
          ...state.activeVideo,
          comments: payload,
        },
      };

    case types.POST_VIDEO_COMMENT:
      return {
        ...state,
        activeVideo: {
          ...state.activeVideo,
          commentsCount: state.activeVideo.commentsCount + 1,
          comments: payload,
        },
      };
    case types.SAVE_VIDEO_TYPE_AND_URL:
      return { ...state, videoInfo: { ...payload } };

    case types.CLEAR_PAGE_DATA_VIDEO_ITEM:
      return { ...state, activeVideo: initialState.activeVideo };
    case types.CLEAR_RELATED_VIDEO_LIST:
      return { ...state, relatedList: initialState.relatedList };

    case types.GET_RELATED_VIDEO_LIST_SUCCESS:
      return { ...state, relatedList: payload };

    case types.DELETE_VIDEO_COMMENT_SUCCESS:
      return {
        ...state,
        activeVideo: {
          ...state.activeVideo,
          comments: state.activeVideo.comments.filter(
            ({ _id }) => _id !== payload,
          ),
          commentsCount: state.activeVideo.commentsCount - 1,
        },
      };

    //errors
    case types.GET_VIDEO_LIST_ERROR:
      return { ...state, loader: false, error: payload };

    //loader
    case types.GET_VIDEO_REQUEST:
      return { ...state, loader: true };

    default:
      return state;
  }
};
