import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CupIcon } from 'assets/images/icons/the_cup_icon.svg';
import cl from 'classnames';
import moment from 'moment';
import { getRandomIndex } from 'utilites';

import { ProgressiveImage } from '../../common';

import s from './styles.module.scss';

export const EventCard = props => {
  const {
    description,
    background,
    startDate,
    endDate,
    playersAvatar,
    title,
    logo,
    className,
  } = props;

  const { t, i18n } = useTranslation();

  const startDay = `${moment(startDate).format('DD')} ${t(
    `characteristics-short-month_${moment(startDate).format(`MMM`)}`,
  )}`;
  const endDay = `${moment(endDate).format('DD')} ${t(
    `characteristics-short-month_${moment(startDate).format(`MMM`)}`,
  )}`;
  const date = `${startDay} - ${endDay}`;

  const playerImage =
    playersAvatar && playersAvatar[getRandomIndex(playersAvatar.length)];
  const playerImageOptions = {
    className: s.bodyPlayer,
  };

  return (
    <div className={cl(s.event, className)}>
      <div className={s.backgroundImageBox}>
        {background && (
          <img src={background} alt="logo" className={s.backgroundImage} />
        )}
      </div>
      <div className={s.header}>
        <span className={s.headerLogoBox}>
          {logo && <img src={logo} alt="logo" className={s.headerLogo} />}
        </span>
        <span>
          <CupIcon />
        </span>
      </div>
      <div className={s.body}>
        <div className={s.bodyLeft}>
          <h3 className={cl(s.bodyText, s.bodyTitle)}>{title}</h3>
          <div className={s.bodyDateBox}>
            <span className={s.bodySubtitle}>{t('team_date_title')}</span>
            <span className={s.bodyText}>{date}</span>
          </div>
          <div className={s.bodyDescriptionBox}>
            {description && (
              <>
                <span className={s.bodySubtitle}>
                  {t('team_info_about_event_title')}
                </span>
                <span className={cl(s.bodyText, s.bodyDescription)}>
                  {description[i18n.language]}
                </span>
              </>
            )}
          </div>
        </div>
        {playerImage && (
          <div className={s.bodyRight}>
            <span>
              <ProgressiveImage
                image={playerImage}
                alt="player"
                options={playerImageOptions}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
