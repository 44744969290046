import React from 'react';

import partnersAnimation from 'animation/titlesLoader/partners.json';
import { LottieAnimation } from 'views/component';

import s from './styles.module.scss';

export const Header = () => {
  return (
    <div className={s.header}>
      <div className={s.title}>
        <LottieAnimation lotti={partnersAnimation} />
      </div>
    </div>
  );
};
