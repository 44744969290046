import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as VideoIcon } from 'assets/images/members/video-icon.svg';
import cl from 'classnames';
import { mediaSliderWidth } from 'constants/media-slider-width';
import {
  playerItemSelector,
  videoLoadedSelector,
  videoSelector,
} from 'redux/selectors';
import { fetchVideoList } from 'redux/store/video/videoOperations';
import { useStateWithCallback, useWindowWidth } from 'utilites';
import { useOnScreen } from 'utilites/customHooks/useOnScreen';
import { scrollIntoView } from 'utilites/scroll';
import {
  ActiveContent,
  LoadMore,
  RosterVideoCard,
  SliderContent,
} from 'views/component';

import s from './styles.module.scss';

const contentInitialValue = {
  status: false,
  url: null,
  title: '',
  type: 'none',
  id: null,
};

export const Videos = () => {
  const dispatch = useDispatch();

  const videoList = useSelector(videoSelector);
  const isLoading = useSelector(videoLoadedSelector);
  const { _id, type } = useSelector(playerItemSelector);

  const [openContent, setOpenContent] =
    useStateWithCallback(contentInitialValue);
  const [limit, setLimit] = useState(6);

  const allContentRef = useRef();
  const contentRef = useRef();
  const isOnScreen = useOnScreen(contentRef);
  const width = useWindowWidth();

  const hasVideos = Boolean(videoList.count);

  useEffect(() => {
    dispatch(fetchVideoList(0, limit, null, 'VIDEO', _id));
  }, [_id]);

  const handleScrollToAllContent = () => {
    scrollIntoView(allContentRef.current, 0, 'smooth', 'start');
  };

  const handleScrollToActiveContent = () => {
    scrollIntoView(contentRef.current, 0, 'smooth', 'start');
  };

  const handleCloseContent = (action = () => {}) => {
    setOpenContent(contentInitialValue, () => action);
  };

  const handleOpenContent = (url, title) => {
    if (openContent.status === false || openContent.url !== url) {
      setOpenContent({ status: true, url, title, type }, () =>
        width > mediaSliderWidth ? handleScrollToActiveContent() : {},
      );
    } else if (openContent.status === true && openContent.url === url) {
      handleCloseContent(handleScrollToAllContent());
    }
  };

  const handleClickLoadMore = () => {
    const updatedLimit = limit + 6;

    dispatch(fetchVideoList(0, updatedLimit, null, 'VIDEO', _id));

    setLimit(updatedLimit);
  };

  const mapVideos =
    hasVideos &&
    videoList.items.map(item => {
      const onClick = () => handleOpenContent(item.url, item.title);
      return (
        <div key={item._id} className={s.listItem}>
          <RosterVideoCard
            handleClick={onClick}
            ref={contentRef}
            activeUrl={openContent.url}
            {...item}
          />
        </div>
      );
    });

  const classes = cl(s.videos, {
    [s.videosHide]: !hasVideos,
  });

  return (
    <div className={classes}>
      <div className={s.caretContainer}>
        <span className={s.caret}>
          <VideoIcon />
        </span>
      </div>

      <h2 className={s.title}>POPULAR VIDEOS</h2>

      <div>
        <div className={s.list} ref={allContentRef}>
          <div className={s.listContainer}>{mapVideos}</div>
          <div
            className={cl(s.listContainerMedia, 'gmt-home__videos-container')}
          >
            <SliderContent
              handleScrollToActiveContent={handleScrollToActiveContent}
              handleScrollToAllContent={handleScrollToAllContent}
              handleCloseContent={handleCloseContent}
              setOpenContent={setOpenContent}
              adaptiveSlides={2}
              listOfItems={videoList}
              openContent={openContent}
              slidesToShow={3}
              type="video"
              contentType={type}
            />
          </div>
          <div className={s.listContainerActive} ref={contentRef}>
            {openContent.type === type && (
              <ActiveContent
                handleScrollToActiveContent={handleScrollToActiveContent}
                handleScrollToAllContent={handleScrollToAllContent}
                handleCloseContent={handleCloseContent}
                openContent={openContent}
                isOnScreen={isOnScreen}
              />
            )}
          </div>
        </div>
        <LoadMore
          canShow={videoList.count > limit}
          isLoading={isLoading}
          disableAnimation
          onClick={handleClickLoadMore}
        />
      </div>
    </div>
  );
};
