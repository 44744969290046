import React from 'react';

import { Header } from './Header';
import { PartnerItems } from './PartnerItems';

const Partners = () => {
  return (
    <div>
      <Header />
      <PartnerItems />
    </div>
  );
};

export default Partners;
