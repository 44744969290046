const types = {
  GET_PLAYERS_REQUEST: 'players/GET_PLAYERS_REQUEST',
  GET_PLAYERS_SUCCESS: 'players/GET_PLAYERS_SUCCESS',
  GET_PLAYERS_ERROR: 'players/GET_PLAYERS_ERROR',

  GET_ROSTER_PLAYERS_REQUEST: 'players/GET_ROSTER_PLAYERS_REQUEST',
  GET_ROSTER_PLAYERS_SUCCESS: 'players/GET_ROSTER_PLAYERS_SUCCESS',
  GET_ROSTER_PLAYERS_ERROR: 'players/GET_ROSTER_PLAYERS_ERROR',

  GET_PLAYER_BY_ID_REQUEST: 'players/GET_PLAYER_BY_ID_REQUEST',
  GET_PLAYER_BY_ID_SUCCESS: 'players/GET_PLAYER_BY_ID_SUCCESS',
  GET_PLAYER_BY_ID_ERROR: 'players/GET_PLAYER_BY_ID_ERROR',

  GET_PLAYER_BY_NAME_REQUEST: 'players/GET_PLAYER_BY_NAME_REQUEST',
  GET_PLAYER_BY_NAME_SUCCESS: 'players/GET_PLAYER_BY_NAME_SUCCESS',
  GET_PLAYER_BY_NAME_ERROR: 'players/GET_PLAYER_BY_NAME_ERROR',

  CLEAR_PAGE_DATA_PLAYER_ITEM: 'players/CLEAR_PAGE_DATA_PLAYER_ITEM',
};

export default types;
