import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { scrollIntoView, useOnScreen } from 'utilites';

import { ActiveContent } from '../ActiveContent';
import { SliderContent } from '../SliderContent';

import './styles.scss';

export const MediaContent = props => {
  const {
    adaptiveSlides,
    setOpenContent,
    openContent,
    listOfItems,
    slides,
    type,
  } = props;

  const { t } = useTranslation();

  const allContentRef = useRef();
  const contentRef = useRef();

  const isOnScreen = useOnScreen(contentRef);

  const handleScrollToAllContent = () => {
    scrollIntoView(allContentRef.current, 0, 'smooth', 'start');
  };

  const handleScrollToActiveContent = () => {
    scrollIntoView(contentRef.current, 0, 'smooth', 'start');
  };

  const handleCloseContent = (action = () => {}) => {
    setOpenContent(
      {
        status: false,
        url: null,
        title: '',
        type: 'none',
        id: null,
      },
      () => action,
    );
  };

  return (
    <>
      {type === 'video' && (
        <div className="gmt-home__videos" ref={allContentRef}>
          <h1 className="gmt-home__videos-title">{t('title_new_videos')}</h1>
          <div className="gmt-home__videos-container">
            <SliderContent
              handleScrollToActiveContent={handleScrollToActiveContent}
              handleScrollToAllContent={handleScrollToAllContent}
              handleCloseContent={handleCloseContent}
              setOpenContent={setOpenContent}
              adaptiveSlides={adaptiveSlides}
              listOfItems={listOfItems}
              openContent={openContent}
              slidesToShow={slides}
              type={type}
            />
            <div className="gmt-home__active-content" ref={contentRef}>
              {openContent.type === type && (
                <ActiveContent
                  handleScrollToActiveContent={handleScrollToActiveContent}
                  handleScrollToAllContent={handleScrollToAllContent}
                  handleCloseContent={handleCloseContent}
                  openContent={openContent}
                  isOnScreen={isOnScreen}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {type === 'stream' && (
        <div className="gmt-home__streams" ref={allContentRef}>
          <h1 className="gmt-home__streams-title">{t('title_streams')}</h1>
          <div className="gmt-home__streams-container">
            <SliderContent
              handleScrollToActiveContent={handleScrollToActiveContent}
              handleScrollToAllContent={handleScrollToAllContent}
              handleCloseContent={handleCloseContent}
              setOpenContent={setOpenContent}
              adaptiveSlides={adaptiveSlides}
              listOfItems={listOfItems}
              openContent={openContent}
              slidesToShow={slides}
              type={type}
            />
            <div className="gmt-home__active-content" ref={contentRef}>
              {openContent.type === type && (
                <ActiveContent
                  handleScrollToActiveContent={handleScrollToActiveContent}
                  handleScrollToAllContent={handleScrollToAllContent}
                  handleCloseContent={handleCloseContent}
                  openContent={openContent}
                  isOnScreen={isOnScreen}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
