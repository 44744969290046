import { useContext } from 'react';

import { SharedClassContext } from './SharedClassContext';

export const useSharedClassContext = () => {
  const ctx = useContext(SharedClassContext);

  if (!ctx) {
    throw new Error(
      'useSharedClassContext must be used within a SharedClassProvider',
    );
  }

  return ctx;
};
