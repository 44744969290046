import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MaximizeIcon } from 'assets/images/maximize.svg';
import { ReactComponent as CloseIcon } from 'assets/images/x-black.svg';

import './styles.scss';

export const ActiveContentBigSize = props => {
  const { handleMaximizeContent, handleCloseContent, title } = props;

  const { t } = useTranslation();

  return (
    <div className="gmt-home__active-content small__container">
      <span className="gmt-home__active-content small__container-left">
        {title}
      </span>
      <div className="gmt-home__active-content small__container-right">
        <span
          onClick={handleMaximizeContent}
          className="gmt-home__active-content small__container-right__maximize"
        >
          <MaximizeIcon className="gmt-home__active-content small__container-right__maximize-icon" />
          {t('video_maximize')}
        </span>
        <span
          onClick={handleCloseContent}
          className="gmt-home__active-content small__container-right__close"
        >
          <CloseIcon className="gmt-home__active-content small__container-right__maximize-icon" />
          {t('video_close')}
        </span>
      </div>
    </div>
  );
};
