import React from 'react';

import s from './styles.module.scss';

export const Header = () => {
  return (
    <div className={s.teamsHeader}>
      <div className={s.title}>
        <h2>VENUES</h2>
      </div>
    </div>
  );
};
