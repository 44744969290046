import React from 'react';

import { ReactComponent as ArrowLeft } from 'assets/images/icons/Scroll_left.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/Scroll_right.svg';
import cl from 'classnames';

import { DOTS, usePagination } from './_hooks';

import './styles.scss';

export const Pagination = props => {
  const {
    totalCount,
    siblingCount = 1,
    pageSize,
    className,
    onPageChange,
    currentPage,
  } = props;

  const paginationRange = usePagination({
    totalCount,
    siblingCount,
    pageSize,
    currentPage,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={cl('pagination-container', { [className]: className })}>
      <li
        className={cl('pagination-item', {
          disabled: currentPage === 1,
        })}
      >
        <button
          onClick={onPrevious}
          className="arrow-btn"
          disabled={currentPage === 1}
        >
          <ArrowLeft />
        </button>
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={cl('pagination-item', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={cl('pagination-item', {
          disabled: currentPage === lastPage,
        })}
      >
        <button
          onClick={onNext}
          className="arrow-btn"
          disabled={currentPage === lastPage}
        >
          <ArrowRight />
        </button>
      </li>
    </ul>
  );
};
