import types from './teamsTypes';

const initialState = {
  teamsList: {
    items: [],
    count: 0,
  },
  teamById: {
    tournaments: [],
    players: [],
    name: '',
  },

  loader: true,
  error: '',
};

export const teamsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_TEAM_BY_NAME_SUCCESS:
    case types.GET_TEAM_BY_ID_SUCCESS:
      return { ...state, teamById: payload, loader: false };

    case types.GET_TEAMS_SUCCESS:
      return { ...state, teamsList: payload, loader: false };

    case types.GET_TEAM_BY_ID_ERROR:
    case types.GET_TEAMS_ERROR:
      return { ...state, error: payload, loader: false };

    case types.GET_TEAM_BY_ID_REQUEST:
    case types.GET_TEAMS_REQUEST:
      return { ...state, loader: true };

    default:
      return state;
  }
};
