import React from 'react';
import { withTranslation } from 'react-i18next';

import refreshIcon from '../../../../assets/images/refresh.png';

import './styles.scss';

const ErrorPage = ({ t }) => {
  const refreshPage = () => window.location.reload();

  return (
    <div className="error-page">
      <p className="error-page__text">{t('global_error_text')}</p>
      <button className="error-page__button" onClick={refreshPage}>
        <img src={refreshIcon} alt="refresh page" />
      </button>
    </div>
  );
};

export default withTranslation()(ErrorPage);
