import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as FacebookIcon } from 'assets/images/members/facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/members/twitter.svg';
import { ReactComponent as VKIcon } from 'assets/images/members/vk.svg';
import { ReactComponent as YoutubeIcon } from 'assets/images/members/youtube.svg';
import moment from 'moment/moment';
import { playerItemSelector } from 'redux/selectors';
import { fetchPlayerByName } from 'redux/store/players/playerOperations';
import { ProgressiveImage } from 'views/component';

import s from './styles.module.scss';

export const Description = () => {
  const dispatch = useDispatch();
  const { name } = useParams();

  const {
    name: { first, last, nick },
    dateInGMT,
    avatar,
    games,
    type,
    facebook,
    youtube,
    twitter,
    vk,
    favouriteGame,
  } = useSelector(playerItemSelector);

  useEffect(() => {
    if (!nick || nick !== name) {
      dispatch(fetchPlayerByName(name));
    }
  }, [name]);

  const imageOptions = {
    className: s.image,
  };

  return (
    <div className={s.description}>
      <div className={s.leftSection}>
        <div className={s.leftSectionBackground}>
          <span className={s.imageBox}>
            <ProgressiveImage
              image={avatar}
              alt={nick}
              options={imageOptions}
            />
          </span>
        </div>
      </div>
      <div className={s.rightSection}>
        <ul className={s.list}>
          <li className={s.listItem}>
            <span className={s.listItemLabel}>NICKNAME</span>
            <span className={s.listItemValue}>{nick}</span>
          </li>
          <li className={s.listItem}>
            <span className={s.listItemLabel}>REAL NAME</span>
            <span className={s.listItemValue}>{`${first} ${last}`}</span>
          </li>
          <li className={s.listItem}>
            <span className={s.listItemLabel}>ROSTER</span>
            <span className={s.listItemValue}>{type}</span>
          </li>
          <li className={s.listItem}>
            <span className={s.listItemLabel}>DAYS IN GMT</span>
            <span className={s.listItemValue}>
              {dateInGMT && moment().diff(dateInGMT, 'days', false)}
            </span>
          </li>
          <li className={s.listItem}>
            <span className={s.listItemLabel}>DISCIPLINE</span>
            <span className={s.listItemValue}>{games[0]?.name}</span>
          </li>
          <li className={s.listItem}>
            <span className={s.listItemLabel}>FAVORITE GAME</span>
            <span className={s.listItemValue}>{favouriteGame || '-'}</span>
          </li>
        </ul>
        <ul className={s.listIcon}>
          {twitter && (
            <li className={s.listIconItem}>
              <a href={twitter} target="_blank" className={s.listIconItemLink}>
                <TwitterIcon />
              </a>
            </li>
          )}
          {youtube && (
            <li className={s.listIconItem}>
              <a href={youtube} target="_blank" className={s.listIconItemLink}>
                <YoutubeIcon />
              </a>
            </li>
          )}
          {vk && (
            <li className={s.listIconItem}>
              <a href={vk} target="_blank" className={s.listIconItemLink}>
                <VKIcon />
              </a>
            </li>
          )}
          {facebook && (
            <li className={s.listIconItem}>
              <a href={facebook} target="_blank" className={s.listIconItemLink}>
                <FacebookIcon />
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
