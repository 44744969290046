import types from './teamsTypes';

export const getTeamsRequest = () => ({
  type: types.GET_TEAMS_REQUEST,
});

export const getTeamsSuccess = (data = {}) => ({
  type: types.GET_TEAMS_SUCCESS,
  payload: data,
});

export const getTeamsError = (error = '') => ({
  type: types.GET_TEAMS_ERROR,
  payload: error,
});

export const getTeamByIdRequest = () => ({
  type: types.GET_TEAM_BY_ID_REQUEST,
});

export const getTeamByIdSuccess = (data = {}) => ({
  type: types.GET_TEAM_BY_ID_SUCCESS,
  payload: data,
});

export const getTeamByIdError = (error = '') => ({
  type: types.GET_TEAM_BY_ID_ERROR,
  payload: error,
});

export const getTeamByNameRequest = () => ({
  type: types.GET_TEAM_BY_NAME_REQUEST,
});

export const getTeamByNameSuccess = (data = {}) => ({
  type: types.GET_TEAM_BY_NAME_SUCCESS,
  payload: data,
});

export const getTeamByNameError = (error = '') => ({
  type: types.GET_TEAM_BY_NAME_ERROR,
  payload: error,
});
