import types from './types';

const initialState = {
  allEvent: {},
  activeListEvent: [],
  activeCategory: { name: 'all', id: 0 },
  showInfoEvents: false,
  listInfoEvents: [],
  listAllInfoEvents: [],
  showLoader: false,
};

export const calendarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.INIT_ALL_EVENT_LIST:
      return { ...state, allEvent: payload };
    case types.SET_ACTIVE_EVENT_LIST:
      return { ...state, activeListEvent: payload };
    case types.SET_ACTIVE_CATEGORY:
      return { ...state, activeCategory: payload };
    case types.INFO_EVENTS_SHOW:
      return { ...state, listInfoEvents: payload, showInfoEvents: true };
    case types.INFO_ALL_EVENTS:
      return { ...state, listAllInfoEvents: payload };
    case types.CLOSE_INFO_EVENT:
      return { ...state, activeCategory: [], showInfoEvents: false };
    case types.ON_LOADER:
      return { ...state, showLoader: true };
    case types.OFF_LOADER:
      return { ...state, showLoader: false };
    default:
      return state;
  }
};
