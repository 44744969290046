import React from 'react';

import aboutUsFirst from 'assets/images/aboutUs/about_us_first.png';
import aboutUsFourth from 'assets/images/aboutUs/about_us_fourth.png';
import aboutUsSecond from 'assets/images/aboutUs/about_us_second.png';
import aboutUsThird from 'assets/images/aboutUs/about_us_third.png';
import { ReactComponent as GmtIcon } from 'assets/images/brand-icon.svg';
import cl from 'classnames';

import s from './styles.module.scss';

const images = [
  {
    img: aboutUsFirst,
    className: s.firstImage,
    key: 'first',
  },
  {
    img: aboutUsSecond,
    className: s.secondImage,
    key: 'second',
  },
  {
    img: aboutUsThird,
    className: s.thirdImage,
    key: 'third',
  },
  {
    img: aboutUsFourth,
    className: s.fourthImage,
    key: 'fourth',
  },
];
export const Header = () => {
  const mapImages = images.map(image => {
    return (
      <span key={image.key} className={cl(image.className, s.image)}>
        <img src={image.img} alt="teams" />
      </span>
    );
  });

  return (
    <div className={s.header}>
      <div className={s.imagesContainer}>{mapImages}</div>

      <div className={s.iconContainer}>
        <span className={s.icon}>
          <GmtIcon />
        </span>
      </div>
    </div>
  );
};
