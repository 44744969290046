const book = Object.freeze({
  home: '/',
  registration: '/registration',
  login: '/login',
  forgot: '/forgot-password',
  reset: '/user/reset-password/:id',
  confirm: '/user/confirm/:id',
  news: '/news',
  newsItem: '/news/:name',
  shop: '/shop',
  streams: '/streams',
  streamsItem: '/streams/:id',
  videos: '/videos',
  videosItem: '/videos/:id',
  photos: '/albums',
  photosAlbum: '/albums/:id',
  games: '/games',
  game: '/games/:id',
  reports: '/reports',
  events: '/event',
  eventsItem: '/event/:id',
  players: '/players',
  playersItem: '/players/:id',
  achievements: '/achievements',
  history: '/history',
  partners: '/partners',
  staff: '/staff',
  contacts: '/contacts',
  profile: '/profile',
  search: '/search',
  notFound: '/not-found',
  landing: '/landing',
  roster: '/roster',
  rosterItem: '/roster/:name',
  teams: '/teams',
  teamsItem: '/teams/:name',
  aboutUs: '/about-us',
  venues: '/venues',
  agency: '/agency',
});

export default book;
