import React from 'react';

import DefaultImg from 'assets/images/default-background-with-logo.png';
import { ReactComponent as YoutubeIcon } from 'assets/images/members/youtube.svg';
import cn from 'classnames';
import moment from 'moment';

import './styles.scss';

export const RosterVideoCard = React.forwardRef(
  (
    {
      title = '',
      dateCreate = '',
      titleImage = '',
      handleClick = () => {},
      activeUrl = null,
      url = '',
      className = '',
    },
    ref,
  ) => {
    const detectErrorImage = e => {
      // temporary solution (must be corrected)
      const className = 'gmt-rosterVideosItem__headerImage';

      if (Number(e.target.height) === 90) {
        e.currentTarget.src = DefaultImg;
      }

      e.currentTarget.className = className;
    };

    return (
      <div
        className={cn(
          'gmt-rosterVideosItem',
          {
            'gmt-rosterVideosItem_active': activeUrl === url,
          },
          className,
        )}
        onClick={handleClick}
        ref={ref}
      >
        <div className="gmt-rosterVideosItem__header">
          <img src={titleImage} alt="video" onLoad={detectErrorImage} />
        </div>

        <div className="gmt-rosterVideosItem__footer">
          <div className="gmt-rosterVideosItem__footerInfo">
            <div className="gmt-rosterVideosItem__footerInfoTitle">{title}</div>
            <div className="gmt-rosterVideosItem__footerInfoSubtitle">
              {moment(dateCreate).fromNow()}
            </div>
          </div>

          <YoutubeIcon className="gmt-rosterVideosItem__footerImage" />
        </div>
      </div>
    );
  },
);
