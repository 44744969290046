import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

const BurgerNavItem = ({
  title,
  href = '/',
  isRedirect = false,
  isWithSubmenu = false,
  onClick,
}) => {
  const Link = useMemo(() => {
    if (isRedirect) return 'a';

    if (isWithSubmenu) return 'span';

    return NavLink;
  }, [isRedirect, isWithSubmenu]);

  const to = isRedirect ? { href } : { to: href };

  const handleClick = e => {
    if (isRedirect) return;

    onClick && onClick(e, href);
  };

  return (
    <Link className="gmt-menu__link" onClick={handleClick} {...to}>
      {title}
    </Link>
  );
};

export default BurgerNavItem;
