import { refreshTokenAuth } from '../store/session/sessionOperations';
import { sessionTypes } from '../store/session/sessionTypes';

const trackTokenStatus = store => next => action => {
  const { type } = action;

  if (type === sessionTypes.TOKEN_EXPIRATION_STATUS) {
    const session = localStorage.getItem('session');

    if (!session) return next(action);

    const { expirationDate } = JSON.parse(session);

    const expiration = new Date(expirationDate);
    const current = new Date();

    const expirationTime = expiration - current;

    window.updateTokenTimeoutId = setTimeout(() => {
      store.dispatch(refreshTokenAuth());
    }, expirationTime - 900000);
    next(action);
  }

  next(action);
};

export default trackTokenStatus;
