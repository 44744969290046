const availableLanguages = ['ru', 'en', 'az'];

// TODO: Disabled all languages except for English.
// If it needs to add another languages, remove the commented code.
export const setSiteLanguage = () => {
  // const browserLang = navigator.language || navigator.userLanguage;
  // const selectedLanguage = localStorage.getItem('language');

  // return selectedLanguage
  //   ? checkingLanguageForAvailability(selectedLanguage)
  //   : checkingLanguageForAvailability(browserLang);

  return availableLanguages[1];
};

// const checkingLanguageForAvailability = (language = '') => {
//   const isAvailable = availableLanguages.includes(language);

//   return isAvailable ? language : availableLanguages[1];
// };
