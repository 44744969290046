import { get, post, remove } from '../apiService';

export const getNewsAll = (offset, limit, tag, lng) => {
  const activeInfo = offset ? '?offset=' + offset : '?offset=0';
  const limitInfo = limit ? '&limit=' + limit : '&limit=6';
  let selectedTag = '&tags=';
  const siteLanguage = '&lang=' + lng;

  if (tag && tag.id !== '01') selectedTag = '&tags=' + tag.id;

  return get('/news/' + activeInfo + limitInfo + selectedTag + siteLanguage);
};

export const getNewsById = id => {
  return get(`/news/${id}/`);
};

export const getNewsByName = name => {
  return get(`/news/name-url?urlByName=${name}`);
};

export const postNewsItemLike = id => {
  return post(`/news/${id}/like/`);
};

export const postNewsItemDislike = id => {
  return post(`/news/${id}/dislike/`);
};

export const postNewsItemComment = (id, body) => {
  return post(`/news/${id}/comment/`, body);
};

export const postNewsItemCommentLike = id => {
  return post(`/comment/${id}/like/`);
};

export const postNewsItemCommentDislike = id => {
  return post(`/comment/${id}/dislike/`);
};

export const deleteNewsItemComment = (newsId, commentId) => {
  return remove(`/news/${newsId}/comment/${commentId}`);
};
