import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Image } from 'assets/images/brand-icon.svg';
import cl from 'classnames';
import book from 'constants/book';
import { loadingStart, loadingStop } from 'redux/store/loading/loadingActions';

import { ProgressiveImage } from '../../common';
import { globalLoaderTypes } from '../../Loaders';

import s from './styles.module.scss';

export const RosterPlayerCard = props => {
  const {
    name: { nick },
    avatar,
    type,
    _id,
    urlByName,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClickCard = () => {
    dispatch(loadingStart(globalLoaderTypes.primary));
    setTimeout(() => {
      dispatch(loadingStop());
      history.push(`${book.roster}/${urlByName}`);
    }, 650);
  };

  return (
    <div className={s.card} onClick={handleClickCard}>
      <div className={s.body}>
        <span className={cl(s.icon, s.iconLeft)}>
          <Image className={s.iconImage} />
        </span>
        <span className={cl(s.icon, s.iconRight)}>
          <Image className={s.iconImage} />
        </span>
        <span className={s.avatar}>
          <ProgressiveImage
            image={avatar}
            alt="player"
            key={_id}
            options={{
              className: s.avatarImage,
            }}
          />
        </span>
      </div>
      <div className={s.footer}>
        <span className={s.nickname}>{nick}</span>
        <span className={s.type}>{type}</span>
      </div>
    </div>
  );
};
