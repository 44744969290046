import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { achievementsReducer } from './achievements/achievementsReducer';
import { bannerReducer } from './banner/bannerReducer';
import { calendarReducer } from './calendar/calendarReduser';
import { categorySelector } from './CategorySelector/CategorySelectorReduser';
import { gamesReducer } from './games/gamesReducer';
import { languageReducer } from './language/languageReducer';
import { liveReducer } from './live/liveReducer';
import { loadingReducer } from './loading/loadingReducer';
import { newsReducer } from './news/newsReducer';
import { notificationReducer } from './notification/notificationReducer';
import { partnersReducer } from './partners/partnersReducer';
import { photoReducer } from './photo/photoReducer';
import { playersReducer } from './players/playersReducer';
import { profileReducer } from './profile/profileReducer';
import { searchReducer } from './search/searchReducer';
import { sessionReducer } from './session/sessionReducer';
import { streamReducer } from './stream/streamReducer';
import { stuffReducer } from './stuff/stuffReducer';
import { teamsReducer } from './teams/teamsReducer';
import { tournamentsReducer } from './tournaments/tournamentsReducer';
import { videoReducer } from './video/videoReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  form: formReducer,
  partners: partnersReducer,
  calendar: calendarReducer,
  banner: bannerReducer,
  stream: streamReducer,
  news: newsReducer,
  video: videoReducer,
  players: playersReducer,
  profile: profileReducer,
  photo: photoReducer,
  search: searchReducer,
  loading: loadingReducer,
  language: languageReducer,
  notification: notificationReducer,
  games: gamesReducer,
  stuff: stuffReducer,
  achievements: achievementsReducer,
  tournaments: tournamentsReducer,
  category: categorySelector,
  live: liveReducer,
  teams: teamsReducer,
});

export default rootReducer;
