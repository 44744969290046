import types from './liveTypes';

const initialState = {
  liveStreams: {
    items: [],
    count: 0,
  },
  loader: true,
  error: '',
};

export const liveReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LIVE_REQUEST:
      return { ...state, loader: true };
    case types.GET_LIVE_SUCCESS:
      return { ...state, liveStreams: payload, loader: false };
    case types.GET_LIVE_ERROR:
      return { ...state, loader: false, error: payload };

    default:
      return state;
  }
};
