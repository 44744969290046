import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { partnersListSelector, partnersLoaderSelector } from 'redux/selectors';
import { fetchPartnersList } from 'redux/store/partners/partnersOperations';
import { createArrayWithLength } from 'utilites/createArrayWithLength';
import { Loader, LoadMore } from 'views/component';

import { PartnerItem } from './PartnerItem';

import s from './styles.module.scss';

export const PartnerItems = () => {
  const dispatch = useDispatch();

  const { items, count } = useSelector(partnersListSelector);
  const isLoading = useSelector(partnersLoaderSelector);

  const [limit, setLimit] = useState(6);

  const handleClickLoadMore = useCallback(() => {
    const updatedLimit = limit + 6;

    dispatch(fetchPartnersList(0, updatedLimit, false));

    setLimit(updatedLimit);
  }, []);

  useEffect(() => {
    dispatch(fetchPartnersList(0, limit, true));
  }, []);

  const mapPartners =
    count > 0
      ? items.map(partner => {
          const {
            _id,
            twitter,
            logo,
            facebook,
            discord,
            youtube,
            vk,
            description,
            site,
            name,
          } = partner;

          return (
            <PartnerItem
              key={_id}
              twitter={twitter}
              logo={logo}
              facebook={facebook}
              discord={discord}
              youtube={youtube}
              vk={vk}
              description={description}
              site={site}
              name={name}
            />
          );
        })
      : null;

  const mapPlugs =
    count > 0 &&
    isLoading &&
    createArrayWithLength(2, (_, idx) => {
      return (
        <div key={idx}>
          <Loader />
        </div>
      );
    });

  return (
    <div className={s.partnerItems}>
      <div className={s.container}>
        {mapPartners}
        {mapPlugs}
        <div>
          <LoadMore
            canShow={count > limit}
            isLoading={isLoading}
            onClick={handleClickLoadMore}
          />
        </div>
      </div>
    </div>
  );
};
