import { reset } from 'redux-form';

import {
  commentVideoDislike,
  commentVideoLike,
  deleteVideoComment,
  getVideo,
  getVideoList,
  postComment,
  videoDislike,
  videoLike,
} from '../../../api/media-service/media-service';
import {
  languageSelector,
  streamSelector,
  videoSelector,
} from '../../selectors';
import { loadingStart, loadingStop } from '../loading/loadingActions';
import { notificationModal } from '../notification/notificationOperations';
import { getStreamSuccess } from '../stream/streamActions';
import * as videoActions from './videoActions';
import {
  deleteVideoCommentError,
  deleteVideoCommentRequest,
  deleteVideoCommentSuccess,
  editVideoCommentsList,
  getRelatedVideoListError,
  getRelatedVideoListRequest,
  getRelatedVideoListSuccess,
  getVideoError,
  getVideoListError,
  getVideoListSuccess,
  getVideoRequest,
  getVideoSuccess,
  postVideoNewComment,
  saveVideoTypeAndUrl,
} from './videoActions';

const fetchVideoRelatedList = item => (dispatch, getState) => {
  const language = languageSelector(getState());

  if (item) {
    const newTag = { ...item.tags[0], id: item.tags[0]._id };
    const articleTag = newTag || '';

    dispatch(getRelatedVideoListRequest());
    return getVideoList(0, 6, articleTag, item.kind, language)
      .then(videoList => {
        dispatch(getRelatedVideoListSuccess(videoList));
      })
      .catch(error => {
        dispatch(getRelatedVideoListError(error));
      });
  }
};

export const fetchVideoList =
  (offset, limit, tag, type, player) => (dispatch, getState) => {
    const language = languageSelector(getState());

    dispatch(getVideoRequest());
    // dispatch(loadingStart());

    return getVideoList(offset, limit, tag, type, language, player)
      .then(response => {
        dispatch(getVideoListSuccess(response));
      })
      .catch(error => {
        dispatch(getVideoListError(error));
      });
  };

export const getVideoItem = id => (dispatch, getState) => {
  const prevStreams = streamSelector(getState());
  const prevVideos = videoSelector(getState());

  dispatch(getVideoRequest());
  dispatch(loadingStart());
  return getVideo(id)
    .then(response => {
      const { platform, url, comments = [] } = response;
      if (platform === 'YOUTUBE') {
        if (url.includes('youtube')) {
          const infoVideo = {
            url: url.split('https://www.youtube.com/watch?v=')[1],
            type: 'YOUTUBE',
          };
          const newVideos = {
            ...prevVideos,
            items: prevVideos.items.map(video =>
              video._id === id
                ? {
                    ...video,
                    views: video.views + 1,
                  }
                : video,
            ),
          };
          dispatch(getVideoListSuccess(newVideos));
          dispatch(saveVideoTypeAndUrl(infoVideo));
        }
      } else if (platform === 'TWITCH') {
        const newStreams = {
          ...prevStreams,
          items: prevStreams.items.map(stream =>
            stream._id === id
              ? {
                  ...stream,
                  views: stream.views + 1,
                }
              : stream,
          ),
        };
        dispatch(getStreamSuccess(newStreams));

        const infoVideo = {
          url: url.includes('video') ? '' : '',
          type: 'YOUTUBE',
        };
      }
      dispatch(getVideoSuccess(response));
      dispatch(editVideoCommentsList(comments));
      dispatch(fetchVideoRelatedList(response));
    })
    .catch(error => dispatch(getVideoError(error)))
    .finally(() => dispatch(loadingStop()));
};

export const likeVideoComment = (id, comment) => dispatch => {
  commentVideoLike(id)
    .then(response => {
      dispatch(updateLikeDisLikeComment(comment, response));
    })
    .catch(error => {
      dispatch(
        notificationModal({
          message: 'notification_reaction',
          notificationType: 'reaction',
        }),
      );
      dispatch(getVideoError(error));
    });
};
export const dislikeVideoComment = (id, comment) => dispatch => {
  commentVideoDislike(id)
    .then(response => {
      dispatch(updateLikeDisLikeComment(comment, response));
    })
    .catch(error => {
      dispatch(getVideoError(error));
      dispatch(
        notificationModal({
          message: 'notification_reaction',
          notificationType: 'reaction',
        }),
      );
    });
};

export const deleteVideoItemComment = (commentId, newsId) => dispatch => {
  dispatch(deleteVideoCommentRequest());

  deleteVideoComment(newsId, commentId)
    .then(() => {
      dispatch(deleteVideoCommentSuccess(commentId));
    })
    .catch(error => {
      dispatch(
        notificationModal({
          message: 'notification_remove-comment-error',
        }),
      );
      dispatch(deleteVideoCommentError(error));
    });
};

const updateLikeDisLikeComment = (comment, res) => dispatch => {
  const newCommentList = comment.map(item => {
    if (item._id === res[0]._id) {
      item.likesCount = res[0].likesCount;
      item.dislikesCount = res[0].dislikesCount;
    }
    return item;
  });
  dispatch(editVideoCommentsList(newCommentList));
};

export const sendComment = (id, body, commentList) => dispatch => {
  postComment(id, body).then(response => {
    dispatch(reset('comment'));
    dispatch(postVideoNewComment([...commentList, response]));
  });
};

export const likeVideo = (id, info) => dispatch => {
  videoLike(id)
    .then(response => {
      const { dislikesCount, likesCount } = response[0];
      dispatch(
        getVideoSuccess({
          ...info,
          likesCount: likesCount,
          dislikesCount: dislikesCount,
        }),
      );
    })
    .catch(error => {
      dispatch(
        notificationModal({
          message: 'notification_reaction',
          notificationType: 'reaction',
        }),
      );
      return dispatch(videoActions.getVideoError(error));
    });
};
export const dislikeVideo = (id, info) => dispatch => {
  videoDislike(id)
    .then(response => {
      const { dislikesCount, likesCount } = response[0];
      dispatch(
        getVideoSuccess({
          ...info,
          likesCount: likesCount,
          dislikesCount: dislikesCount,
        }),
      );
    })
    .catch(error => {
      dispatch(videoActions.getVideoError(error));
      dispatch(
        notificationModal({
          message: 'notification_reaction',
          notificationType: 'reaction',
        }),
      );
    });
};

export const clearPageDataActiveVideo = () => dispatch =>
  dispatch(videoActions.clearPageDataVideoItem());

export const clearRelatedVideoList = () => dispatch =>
  dispatch(videoActions.clearRelatedVideoList());
