import { types } from './loadingTypes';

const initialState = { isLoading: true, loadingTypes: null };

export const loadingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOADING_START:
      return { isLoading: true, loadingTypes: payload };

    case types.LOADING_STOP:
      return {
        isLoading: false,
        loadingTypes: null,
      };

    default:
      return state;
  }
};
