import React from 'react';
import { withTranslation } from 'react-i18next';

import cl from 'classnames';

import { Loader } from '../../Loaders';

import './styles.scss';

export const LoadMore = withTranslation()(props => {
  const {
    t,
    canShow = true,
    isLoading,
    loaderSize = 64,
    onClick,
    disableAnimation = false,
  } = props;

  if (!canShow) {
    return null;
  }

  const buttonContent = isLoading ? (
    <Loader size={loaderSize} />
  ) : (
    <span
      className="gmt-buttons__load-more-label"
      data-letters={t('load_more')}
    >
      {t('load_more')}
    </span>
  );

  const classes = cl('gmt-buttons__load-more', {
    'gmt-buttons__load-more--disabled-animation': disableAnimation,
  });

  return (
    <button data-disabled={isLoading} className={classes} onClick={onClick}>
      {buttonContent}
    </button>
  );
});
