import React from 'react';
import { useSelector } from 'react-redux';

import { newsLoaderSelector, newsSelector } from 'redux/selectors';
import { createArrayWithLength } from 'utilites/createArrayWithLength';
import { Loader, NewsCard } from 'views/component';

import s from './styles.module.scss';

export const Cards = () => {
  const { items, count } = useSelector(newsSelector);
  const isLoading = useSelector(newsLoaderSelector);

  const mapNews =
    count > 0 &&
    items.map(news => {
      const { _id, title, dateCreate, body, urlByName } = news;
      return (
        <NewsCard
          key={_id}
          title={title}
          dateCreate={dateCreate}
          body={body}
          urlByName={urlByName}
        />
      );
    });

  const mapPlugs =
    count > 0 &&
    isLoading &&
    createArrayWithLength(2, (_, idx) => {
      return (
        <div key={idx} className={s.loaderContainer}>
          <Loader />
        </div>
      );
    });

  return (
    <div className={s.news}>
      {mapNews}
      {mapPlugs}
    </div>
  );
};
