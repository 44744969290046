import types from './notificationTypes';

const initialState = {
  status: false,
  message: '',
  notificationType: '',
};

export const notificationReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case types.OPEN_NOTIFICATION_MODAL:
      return {
        status: true,
        message: payload.message,
        notificationType: payload.notificationType,
      };
    case types.CLOSE_NOTIFICATION_MODAL:
      return initialState;
    default:
      return state;
  }
};
