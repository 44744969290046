import React from 'react';
import YouTube from 'react-youtube';

import './styles.scss';

export const VideoPlayer = props => {
  const { videoLink } = props;

  const opts = () => {};
  const renderPlayers = link => ({
    TWITCH: (
      <iframe
        src={link}
        frameBorder="0"
        scrolling="no"
        allowFullScreen={true}
        className="video-player__iframe"
        title="videoPlayer"
      />
    ),
    YOUTUBE: (
      <YouTube
        videoId={videoIdForYoutube}
        opts={opts()}
        scrolling="no"
        className="video-player__iframe"
        onReady={e => {
          console.log(e);
        }}
      />
    ),
  });

  const getPlatformType = urlLink => {
    if (urlLink.includes('youtube')) {
      return 'YOUTUBE';
    }
    if (urlLink.includes('twitch')) {
      return 'TWITCH';
    }
  };

  const createLinkForTwitch = () => {
    const kind = 'STREAM';

    return kind
      ? kind === 'STREAM'
        ? `https://player.twitch.tv/?channel=${
            videoLink.split('https://www.twitch.tv/')[1]
          }&parent=${window.location.hostname}`
        : `https://player.twitch.tv/?video=${
            videoLink.split('/videos/')[1]
          }&parent=${window.location.hostname}`
      : '';
  };

  const platform = getPlatformType(videoLink);

  const videoIdForYoutube =
    platform === 'YOUTUBE'
      ? videoLink.split('https://www.youtube.com/watch?v=')[1]
      : null;

  return (
    <div className="video-player__video">
      <div className="video-player__iframe-container">
        <>
          {platform === 'YOUTUBE' && renderPlayers().YOUTUBE}
          {platform === 'TWITCH' && renderPlayers(createLinkForTwitch()).TWITCH}
        </>
      </div>
    </div>
  );
};
