import React from 'react';
import { useTranslation } from 'react-i18next';

import { socialConfig } from '../footerConfig';

import s from './styles.module.scss';

export const SocialSection = () => {
  const { t } = useTranslation();

  const mapSocialLinks = socialConfig.map(link => {
    return (
      <li className={s.listItem} key={link.name}>
        <a href={link.ref} aria-label={link.name}>
          {link.IconComponent}
        </a>
      </li>
    );
  });

  return (
    <div className={s.socialSection}>
      <div className={s.container}>
        <div className={s.info}>
          <p className={s.infoText}>{t('gmt_footer_protected_subtitle')}</p>
        </div>
        <ul className={s.list}>{mapSocialLinks}</ul>
      </div>
    </div>
  );
};
