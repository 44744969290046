import types from './tournamentsTypes';

export const getTournamentsRequest = () => ({
  type: types.GET_TOURNAMENTS_REQUEST,
});

export const getTournamentsSuccess = (data = {}) => ({
  type: types.GET_TOURNAMENTS_SUCCESS,
  payload: data,
});

export const getTournamentsError = (error = '') => ({
  type: types.GET_TOURNAMENTS_ERROR,
  payload: error,
});

export const getTournamentByIdRequest = () => ({
  type: types.GET_TOURNAMENT_BY_ID_REQUEST,
});

export const getTournamentByIdSuccess = (data = {}) => ({
  type: types.GET_TOURNAMENT_BY_ID_SUCCESS,
  payload: data,
});

export const getTournamentByIdError = (error = '') => ({
  type: types.GET_TOURNAMENT_BY_ID_ERROR,
  payload: error,
});
