/**
 * This function creates an array of a specified length and maps each element using a provided
 * function.
 * @param length - The length parameter is the desired length of the array to be created.
 * @param map - `map` is a function that will be applied to each element of the array created by
 * `createArrayWithLength`. It takes one argument, which is the value of the current element being
 * processed, and returns a new value for that element. The `map` function is optional, so if it
 * @returns The function `createArrayWithLength` returns an array of length `length` where each element
 * is the result of applying the function `map` to the value `0`.
 */
export function createArrayWithLength(length, map) {
  return Array(length).fill(0).map(map);
}
