import types from './newsTypes';

const initialState = {
  allNews: { items: [], count: 0 },
  commentCountItem: 0,
  newsItem: {},
  loader: true,
  error: '',
};

export const newsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_NEWS_SUCCESS:
      return { ...state, allNews: payload, loader: false };

    case types.POST_NEWS_ITEM_LIKE_SUCCESS:
      return {
        ...state,
        newsItem: {
          ...state.newsItem,
          likesCount: payload.likesCount,
          dislikesCount: payload.dislikesCount,
        },
      };

    case types.POST_NEWS_ITEM_COMMENT_SUCCESS:
      return {
        ...state,
        newsItem: {
          ...state.newsItem,
          commentsCount: state.newsItem.commentsCount + 1,
          comments: [...state.newsItem.comments, payload],
        },
      };

    case types.POST_NEWS_ITEM_COMMENT_LIKE_SUCCESS:
      return {
        ...state,
        newsItem: {
          ...state.newsItem,
          comments: payload,
        },
      };

    case types.POST_NEWS_ITEM_COMMENT_DISLIKE_SUCCESS:
      return {
        ...state,
        newsItem: {
          ...state.newsItem,
          comments: payload,
        },
      };

    case types.POST_NEWS_ITEM_DISLIKE_SUCCESS:
      return {
        ...state,
        newsItem: {
          ...state.newsItem,
          likesCount: payload.likesCount,
          dislikesCount: payload.dislikesCount,
        },
      };

    case types.DELETE_NEWS_ITEM_COMMENT_SUCCESS:
      return {
        ...state,
        newsItem: {
          ...state.newsItem,
          comments: state.newsItem.comments.filter(
            ({ _id }) => _id !== payload,
          ),
          commentsCount: state.newsItem.commentsCount - 1,
        },
      };

    case types.GET_NEWS_BY_NAME_SUCCESS:
    case types.GET_NEWS_BY_ID_SUCCESS:
      return { ...state, newsItem: payload };

    case types.CLEAR_PAGE_DATA_NEWS_ITEM:
      return { ...state, newsItem: initialState.newsItem };

    //errors

    case types.GET_NEWS_ERROR:
      return { ...state, error: payload, loader: false };

    //loader

    case types.GET_NEWS_REQUEST:
      return { ...state, loader: true };

    default:
      return state;
  }
};
