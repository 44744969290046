import React from 'react';

import newsAnimation from 'animation/titlesLoader/news.json';
import { LottieAnimation } from 'views/component';

import s from './styles.module.scss';

export const Header = () => {
  return (
    <div className={s.header}>
      <div className={s.headerTitle}>
        <LottieAnimation lotti={newsAnimation} />
      </div>
    </div>
  );
};
