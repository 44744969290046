import React from 'react';
import { useSelector } from 'react-redux';

import { teamsListLoaderSelector, teamsListSelector } from 'redux/selectors';
import { createArrayWithLength } from 'utilites/createArrayWithLength';
import { Loader, TeamCard } from 'views/component';

import s from './styles.module.scss';

export const TeamCards = () => {
  const { count, items } = useSelector(teamsListSelector);
  const isLoading = useSelector(teamsListLoaderSelector);

  const mapCardLoaders =
    count > 0 && isLoading
      ? createArrayWithLength(2, (_, index) => {
          return (
            <div key={index} className={s.loaderBox}>
              <Loader size={67} />
            </div>
          );
        })
      : null;

  const mapCardTeams =
    count > 0
      ? items.map(item => {
          return (
            <div key={item._id} className={s.cardBox}>
              <TeamCard
                name={item.name}
                players={item.players}
                urlByName={item.urlByName}
              />
            </div>
          );
        })
      : null;

  return (
    <div className={s.teamCards}>
      {mapCardTeams}
      {mapCardLoaders}
    </div>
  );
};
