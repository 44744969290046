import React from 'react';

import { ClientSection } from './ClientSection';
import { NavSection } from './NavSection';
import { SocialSection } from './SocialSection';

import s from './styles.module.scss';

export const Footer = () => {
  return (
    <footer className={s.footer}>
      <NavSection />
      <SocialSection />
      <ClientSection />
    </footer>
  );
};
