const types = {
  GET_GAMES_REQUEST: 'games/GET_GAMES_REQUEST',
  GET_GAMES_SUCCESS: 'games/GET_GAMES_SUCCESS',
  GET_GAMES_ERROR: 'games/GET_GAMES_ERROR',

  GET_GAME_BY_ID_REQUEST: 'games/GET_GAME_BY_ID_REQUEST',
  GET_GAME_BY_ID_SUCCESS: 'games/GET_GAME_BY_ID_SUCCESS',
  GET_GAME_BY_ID_ERROR: 'games/GET_GAME_BY_ID_ERROR',

  GET_GAME_PLAYERS_FIRST_TEAM_REQUEST:
    'games/GET_GAME_PLAYERS_FIRST_TEAM_REQUEST',
  GET_GAME_PLAYERS_FIRST_TEAM_SUCCESS:
    'games/GET_GAME_PLAYERS_FIRST_TEAM_SUCCESS',
  GET_GAME_PLAYERS_FIRST_TEAM_ERROR: 'games/GET_GAME_PLAYERS_FIRST_TEAM_ERROR',

  GET_GAME_PLAYERS_SECOND_TEAM_REQUEST:
    'games/GET_GAME_PLAYERS_SECOND_TEAM_REQUEST',
  GET_GAME_PLAYERS_SECOND_TEAM_SUCCESS:
    'games/GET_GAME_PLAYERS_SECOND_TEAM_SUCCESS',
  GET_GAME_PLAYERS_SECOND_TEAM_ERROR:
    'games/GET_GAME_PLAYERS_SECOND_TEAM_ERROR',

  POST_GAME_ITEM_LIKE_REQUEST: 'games/POST_GAME_ITEM_LIKE_REQUEST',
  POST_GAME_ITEM_LIKE_SUCCESS: 'games/POST_GAME_ITEM_LIKE_SUCCESS',
  POST_GAME_ITEM_LIKE_ERROR: 'games/POST_GAME_ITEM_LIKE_ERROR',

  POST_GAME_ITEM_DISLIKE_REQUEST: 'games/POST_GAME_ITEM_DISLIKE_REQUEST',
  POST_GAME_ITEM_DISLIKE_SUCCESS: 'games/POST_GAME_ITEM_DISLIKE_SUCCESS',
  POST_GAME_ITEM_DISLIKE_ERROR: 'games/POST_GAME_ITEM_DISLIKE_ERROR',

  POST_GAME_ITEM_COMMENT_REQUEST: 'games/POST_GAME_ITEM_COMMENT_REQUEST',
  POST_GAME_ITEM_COMMENT_SUCCESS: 'games/POST_GAME_ITEM_COMMENT_SUCCESS',
  POST_GAME_ITEM_COMMENT_ERROR: 'games/POST_GAME_ITEM_COMMENT_ERROR',

  DELETE_GAME_ITEM_COMMENT_REQUEST: 'games/DELETE_GAME_ITEM_COMMENT_REQUEST',
  DELETE_GAME_ITEM_COMMENT_SUCCESS: 'games/DELETE_GAME_ITEM_COMMENT_SUCCESS',
  DELETE_GAME_ITEM_COMMENT_ERROR: 'games/DELETE_GAME_ITEM_COMMENT_ERROR',

  POST_GAME_ITEM_COMMENT_LIKE_REQUEST:
    'games/POST_GAME_ITEM_COMMENT_LIKE_REQUEST',
  POST_GAME_ITEM_COMMENT_LIKE_SUCCESS:
    'games/POST_GAME_ITEM_COMMENT_LIKE_SUCCESS',
  POST_GAME_ITEM_COMMENT_LIKE_ERROR: 'games/POST_GAME_ITEM_COMMENT_LIKE_ERROR',

  POST_GAME_ITEM_COMMENT_DISLIKE_REQUEST:
    'games/POST_GAME_ITEM_COMMENT_DISLIKE_REQUEST',
  POST_GAME_ITEM_COMMENT_DISLIKE_SUCCESS:
    'games/POST_GAME_ITEM_COMMENT_DISLIKE_SUCCESS',
  POST_GAME_ITEM_COMMENT_DISLIKE_ERROR:
    'games/POST_GAME_ITEM_COMMENT_DISLIKE_ERROR',
};

export default types;
