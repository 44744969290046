import { types } from './stuffTypes';

const initialState = [];

export const stuffReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_STUFF_SUCCESS:
      return payload;

    default:
      return state;
  }
};
