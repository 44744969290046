import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BrandIcon } from 'assets/images/brand-icon.svg';
import { RosterPlayerCard } from 'views/component';

import s from './styles.module.scss';

export const Players = memo(props => {
  const { players, innerRef } = props;

  const { t } = useTranslation();

  const mapPlayers =
    players.length > 0 &&
    players.map(item => {
      return <RosterPlayerCard key={item._id} {...item} />;
    });

  return (
    <div className={s.players} ref={innerRef}>
      <div className={s.container}>
        <div className={s.labelBox}>
          <span>
            <BrandIcon className={s.labelIcon} />
          </span>
          <h2 className={s.labelText}>{t('team_players_title')}</h2>
        </div>
        <div className={s.items}>{mapPlayers}</div>
      </div>
    </div>
  );
});
