import { get } from '../apiService';

export const getPartnersList = (offset, limit) => {
  const activeInfo = offset ? '?offset=' + offset : '?offset=0';
  const limitInfo = limit ? '&limit=' + limit : '&limit=6';
  return get('/partner/' + activeInfo + limitInfo);
};

export const getPartner = id => {
  return get('/partner/' + id);
};
