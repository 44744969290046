import React from 'react';

import { ReactComponent as Location } from 'assets/images/location.svg';

import s from './styles.module.scss';

export const LeftSection = props => {
  const { title, description, location } = props;
  return (
    <div className={s.leftSection}>
      <div className={s.headerBox}>
        <h2 className={s.title}>{title}</h2>
        <p className={s.description}>{description}</p>
      </div>
      <div className={s.footerBox}>
        <span>
          <Location />
        </span>
        <div className={s.locationWrapper}>
          <h5 className={s.locationTitle}>Location</h5>
          <p className={s.locationDescription}>{location}</p>
        </div>
      </div>
    </div>
  );
};
