import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadingStart, loadingStop } from 'redux/store/loading/loadingActions';

import { globalLoaderTypes } from '../../component';
import { Cards } from './Cards';
import { Header } from './Header';

import s from './styles.module.scss';

const Venues = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingStart(globalLoaderTypes.tertiary));
    let timer = setTimeout(() => dispatch(loadingStop()), 650);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={s.venues}>
      <Header />
      <Cards />
    </div>
  );
};

export default Venues;
