import { categoriesList } from '../../../constants/categories';
import types from './searchTypes';

const initialState = {
  count: 0,
  currentCategory: categoriesList.ALL,
  searchQuery: '',
  items: [],
};

export const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_SEARCH_SUCCESS:
      return payload;
    case types.SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: payload,
      };
    default:
      return state;
  }
};
