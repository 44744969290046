import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { teamsListLoaderSelector, teamsListSelector } from 'redux/selectors';
import { fetchTeamsList } from 'redux/store/teams/teamsOperations';
import { LoadMore } from 'views/component/buttons';

import { Header } from './Header';
import { TeamCards } from './TeamCards';

import s from './styles.module.scss';

const Teams = () => {
  const { count } = useSelector(teamsListSelector);
  const isLoading = useSelector(teamsListLoaderSelector);
  const [limit, setLimit] = useState(6);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTeamsList(true, 0, limit));
  }, []);

  const handleClickLoadMore = () => {
    const updatedLimit = limit + 6;

    dispatch(fetchTeamsList(false, 0, updatedLimit));

    setLimit(updatedLimit);
  };

  return (
    <div className={s.teams}>
      <Header />
      <TeamCards />
      <LoadMore
        canShow={count > limit}
        isLoading={isLoading}
        onClick={handleClickLoadMore}
      />
    </div>
  );
};

export default Teams;
