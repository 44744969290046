import React from 'react';

import s from './styles.module.scss';

export const Sponsors = props => {
  const { sponsors } = props;

  const mapSponsors = sponsors.map(sponsor => {
    return (
      <li key={sponsor.id}>
        <img src={sponsor.logo} />
      </li>
    );
  });

  return <ul className={s.sponsorsList}>{mapSponsors}</ul>;
};
