import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import trackTokenStatus from '../middleware/trackTokenStatus';
import rootReducer from './rootReduser';

const environment = process.env.NODE_ENV;

const middlewares = [thunk, trackTokenStatus];
const devtools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const enhancer =
  environment === 'development'
    ? compose(applyMiddleware(...middlewares), devtools)
    : compose(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, enhancer);
