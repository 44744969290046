import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { teamByIdSelector } from 'redux/selectors';
import { fetchTeamByName } from 'redux/store/teams/teamsOperations';
import { scrollIntoView } from 'utilites';

import { Events } from './Events';
import { Header } from './Header';
import { Players } from './Players';

import s from './styles.module.scss';

const TeamsItem = () => {
  const dispatch = useDispatch();
  const { name: urlByName } = useParams();
  const { t } = useTranslation();

  const { name, players, tournaments } = useSelector(teamByIdSelector);

  const playersRef = useRef();
  const eventsRef = useRef();

  useEffect(() => {
    dispatch(fetchTeamByName(urlByName));
  }, []);

  const handleScrollToContent = ref => {
    scrollIntoView(ref.current);
  };

  return (
    <div className={s.teamItem}>
      <div>
        <div className={s.headerBox}>
          <Header players={players} name={name} />
        </div>
        <div className={s.contentBox}>
          <div className={s.anchors}>
            <span
              className={s.anchorsItem}
              onClick={() => handleScrollToContent(playersRef)}
            >
              {t('team_players_title')}
            </span>
            <span
              className={s.anchorsItem}
              onClick={() => handleScrollToContent(eventsRef)}
            >
              {t('team_events_title')}
            </span>
          </div>
          <Players players={players} innerRef={playersRef} />
          <Events tournaments={tournaments} innerRef={eventsRef} />
        </div>
      </div>
    </div>
  );
};

export default TeamsItem;
