import React from 'react';
import { useTranslation } from 'react-i18next';

import { aboutUsConfig } from '../aboutUs.config';

import s from './styles.module.scss';

export const Body = () => {
  const { t } = useTranslation();

  const mapItems = aboutUsConfig.map(item => {
    return (
      <p key={item} className={s.item}>
        {t(item)}
      </p>
    );
  });

  return (
    <div className={s.body}>
      <h2 className={s.title}>WHO ARE WE</h2>
      <div className={s.itemContainer}>{mapItems}</div>
    </div>
  );
};
