import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Icons
import { ReactComponent as LogoIcon } from 'assets/images/header/new-header-icon.svg';
import cn from 'classnames';
import book from 'constants/book';
// Store
import { loadingStart, loadingStop } from 'redux/store/loading/loadingActions';
// Constants
import { globalLoaderTypes } from 'views/component/Loaders/GlobalLoader/GlobalLoader.config';

// Components
import BurgerNav from './BurgerNav/BurgerNav';

import './BurgerMenu.styles.scss';

const BurgerMenu = ({ resizeHeader, navItems }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bodyRef = useRef();

  const [open, setOpen] = useState(false);

  const handleRedirect = useCallback(
    (e, ref) => {
      e.preventDefault();
      dispatch(loadingStart(globalLoaderTypes.primary));
      setTimeout(() => {
        setOpen(false);
        dispatch(loadingStop());
        history.push(ref);
        resizeHeader();
      }, 650);
    },
    [history, dispatch, resizeHeader],
  );

  const toggleMenuVisibility = () => {
    setOpen(prevState => !prevState);
  };

  useEffect(() => {
    bodyRef.current = document.getElementsByTagName('body')[0];
  }, []);

  useEffect(() => {
    const trigger = open ? 'add' : 'remove';

    bodyRef?.current?.classList[trigger]('tablet-prevent-scroll');
  }, [open]);

  const burgerItemClassNames = cn('gmt-burger__item', {
    'gmt-burger__item_open': open,
  });

  return (
    <div className="gmt-burger-menu">
      <div className="gmt-burger-menu__topLine">
        <div className="gmt-burger-menu__logo">
          <LogoIcon
            className="gmt-burger-menu__logo-image"
            onClick={e => handleRedirect(e, book.landing)}
          />
        </div>

        <div className="gmt-burger" open={open} onClick={toggleMenuVisibility}>
          <div className={burgerItemClassNames} />
          <div className={burgerItemClassNames} />
          <div className={burgerItemClassNames} />
        </div>
      </div>

      <BurgerNav navItems={navItems} isOpen={open} onClick={handleRedirect} />
    </div>
  );
};

export default BurgerMenu;
