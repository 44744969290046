import React from 'react';
import { useSelector } from 'react-redux';

import { useSharedClassContext } from 'contexts';
import { playersLoaderSelector, rosterPlayersSelector } from 'redux/selectors';
import { createArrayWithLength } from 'utilites/createArrayWithLength';
import { Loader, RosterPlayerCard } from 'views/component';

import './styles.scss';

export const Cards = () => {
  const { appendClass } = useSharedClassContext();

  const isLoading = useSelector(playersLoaderSelector);
  const { items, count } = useSelector(rosterPlayersSelector);

  const mapPlugs =
    count > 0 &&
    isLoading &&
    createArrayWithLength(3, (_, idx) => {
      return (
        <div key={idx} className={appendClass('__players-card-loader')}>
          <Loader />
        </div>
      );
    });

  const mapPlayers =
    count > 0 &&
    items.map(item => {
      return <RosterPlayerCard key={item._id} {...item} />;
    });

  return (
    <div className={appendClass('__players')}>
      {mapPlayers}
      {mapPlugs}
    </div>
  );
};
