import React from 'react';

import { ProgressiveImage } from 'views/component';

import s from './styles.module.scss';

export const PlayersSection = props => {
  const { players = [] } = props;

  const mapPlayers =
    Array.isArray(players) && players.length
      ? players.map((player, idx) => {
          const options = {
            className: s.itemimage,
          };

          return (
            <span
              key={player._id}
              className={s.item}
              style={{
                '--item-number': idx,
              }}
            >
              <ProgressiveImage
                image={player.avatar}
                alt="player"
                options={options}
              />
            </span>
          );
        })
      : null;

  return <div className={s.players}>{mapPlayers}</div>;
};
