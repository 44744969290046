import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { ReactComponent as LogoIcon } from 'assets/images/footer/var-3/logo.svg';

import {
  loadingStart,
  loadingStop,
} from '../../../../redux/store/loading/loadingActions';
import { globalLoaderTypes } from '../../Loaders/GlobalLoader/GlobalLoader.config';
import { linksConfig } from '../footerConfig';

import s from './styles.module.scss';

export const NavSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRedirect = (e, ref) => {
    e.preventDefault();
    dispatch(loadingStart(globalLoaderTypes.primary));
    setTimeout(() => {
      dispatch(loadingStop());
      history.push(ref);
    }, 750);
  };

  const mapLinks = linksConfig.map(link => {
    return (
      <li className={s.navListItem} key={link.ref}>
        <NavLink
          className={s.link}
          activeClassName={s.linkActive}
          to={link.ref}
          onClick={e => handleRedirect(e, link.ref)}
        >
          {t(link.title)}
        </NavLink>
      </li>
    );
  });

  return (
    <div className={s.navSection}>
      <span className={s.logo}>
        <LogoIcon />
      </span>
      <nav className={s.nav}>
        <ul className={s.navList}>{mapLinks}</ul>
      </nav>
    </div>
  );
};
