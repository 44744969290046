import { sessionTypes } from './sessionTypes';

export const loginRequest = () => ({
  type: sessionTypes.LOGIN_REQUEST,
});

export const loginSuccess = () => ({
  type: sessionTypes.LOGIN_SUCCESS,
});

export const loginSuccessConfirm = data => ({
  type: sessionTypes.LOGIN_SUCCESS_CONFIRM,
  payload: data,
});

export const loginError = error => ({
  type: sessionTypes.LOGIN_ERROR,
  payload: error,
});

export const forgotRequest = () => ({
  type: sessionTypes.FORGOT_REQUEST,
});

export const forgotSuccess = data => ({
  type: sessionTypes.FORGOT_SUCCESS,
  payload: data,
});

export const forgotError = error => ({
  type: sessionTypes.FORGOT_ERROR,
  payload: error,
});

export const resetRequest = () => ({
  type: sessionTypes.RESET_REQUEST,
});

export const resetSuccess = () => ({
  type: sessionTypes.RESET_SUCCESS,
});

export const resetError = error => ({
  type: sessionTypes.RESET_ERROR,
  payload: error,
});

export const confirmRequest = () => ({
  type: sessionTypes.CONFIRM_REQUEST,
});

export const confirmSuccess = token => ({
  type: sessionTypes.CONFIRM_SUCCESS,
  payload: token,
});

export const confirmError = error => ({
  type: sessionTypes.CONFIRM_ERROR,
  payload: error,
});

export const reSendConfirmCodeRequest = () => ({
  type: sessionTypes.RE_SEND_CONFIRM_CODE_REQUEST,
});

export const reSendConfirmCodeSuccess = () => ({
  type: sessionTypes.RE_SEND_CONFIRM_CODE_SUCCESS,
});

export const reSendConfirmCodeError = error => ({
  type: sessionTypes.RE_SEND_CONFIRM_CODE_ERROR,
  payload: error,
});

export const signupRequest = () => ({
  type: sessionTypes.SIGNUP_REQUEST,
});

export const signupSuccess = () => ({
  type: sessionTypes.SIGNUP_SUCCESS,
});

export const signupSuccessConfirm = data => ({
  type: sessionTypes.SIGNUP_SUCCESS_CONFIRM,
  payload: data,
});

export const signupError = error => ({
  type: sessionTypes.SIGNUP_ERROR,
  payload: error,
});

export const logOutStart = () => ({
  type: sessionTypes.LOGOUT_START,
});

export const logOutSuccess = () => ({
  type: sessionTypes.LOGOUT_SUCCESS,
});

export const logOutError = () => ({
  type: sessionTypes.LOGOUT_ERROR,
});

export const getCurrentStart = () => ({
  type: sessionTypes.GET_CURRENT_START,
});

export const getCurrentSuccess = () => ({
  type: sessionTypes.GET_CURRENT_SUCCESS,
});

export const getCurrentSuccessConfirm = token => ({
  type: sessionTypes.GET_CURRENT_SUCCESS_CONFIRM,
  payload: token,
});

export const getCurrentError = error => ({
  type: sessionTypes.GET_CURRENT_ERROR,
  payload: { error },
});

export const tokenExpirationStatus = () => ({
  type: sessionTypes.TOKEN_EXPIRATION_STATUS,
});

export const saveUserId = userId => ({
  type: sessionTypes.SAVE_USER_ID,
  payload: userId,
});
