const types = {
  GET_TEAMS_REQUEST: 'teams/GET_TEAMS_REQUEST',
  GET_TEAMS_SUCCESS: 'teams/GET_TEAMS_SUCCESS',
  GET_TEAMS_ERROR: 'teams/GET_TEAMS_ERROR',

  GET_TEAM_BY_ID_REQUEST: 'teams/GET_TEAM_BY_ID_REQUEST',
  GET_TEAM_BY_ID_SUCCESS: 'teams/GET_TEAM_BY_ID_SUCCESS',
  GET_TEAM_BY_ID_ERROR: 'teams/GET_TEAM_BY_ID_ERROR',

  GET_TEAM_BY_NAME_REQUEST: 'teams/GET_TEAM_BY_NAME_REQUEST',
  GET_TEAM_BY_NAME_SUCCESS: 'teams/GET_TEAM_BY_NAME_SUCCESS',
  GET_TEAM_BY_NAME_ERROR: 'teams/GET_TEAM_BY_NAME_ERROR',
};

export default types;
