import React from 'react';
import Lottie from 'react-lottie';

import cn from 'classnames';

export function LottieAnimation(props) {
  const {
    className,
    completedCallback = () => {},
    loop = true,
    lotti,
    onClick = () => {},
  } = props;

  const defaultOptions = {
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    animationData: lotti,
    autoplay: true,
    loop,
  };

  return (
    <div className={cn(className)} onClick={onClick}>
      <Lottie
        isClickToPauseDisabled={true}
        options={defaultOptions}
        eventListeners={[
          {
            eventName: 'complete',
            callback: completedCallback,
          },
        ]}
      />
    </div>
  );
}
