import * as api from '../../../api/banner-service/banner-service';
import { globalLoaderTypes } from '../../../views/component/Loaders/GlobalLoader/GlobalLoader.config';
import { loadingStart, loadingStop } from '../loading/loadingActions';
import * as bannerActions from './bannerActions';

export const fetchBanners = () => dispatch => {
  dispatch(bannerActions.getBannersRequest());
  dispatch(loadingStart(globalLoaderTypes.secondary));

  return api
    .getBannersList()
    .then(({ items }) => {
      dispatch(bannerActions.getBannersSuccess(items));
    })
    .catch(error => {
      dispatch(bannerActions.getBannersError(error));
    })
    .finally(() => {
      dispatch(loadingStart(globalLoaderTypes.tertiary));
      setTimeout(() => dispatch(loadingStop()), 650);
    });
};
