const types = {
  GET_NEWS_REQUEST: 'news/GET_NEWS_REQUEST',
  GET_NEWS_SUCCESS: 'news/GET_NEWS_SUCCESS',
  GET_NEWS_ERROR: 'news/GET_NEWS_ERROR',

  GET_NEWS_BY_ID_REQUEST: 'news/GET_NEWS_BY_ID_REQUEST',
  GET_NEWS_BY_ID_SUCCESS: 'news/GET_NEWS_BY_ID_SUCCESS',
  GET_NEWS_BY_ID_ERROR: 'news/GET_NEWS_BY_ID_ERROR',

  GET_NEWS_BY_NAME_REQUEST: 'news/GET_NEWS_BY_NAME_REQUEST',
  GET_NEWS_BY_NAME_SUCCESS: 'news/GET_NEWS_BY_NAME_SUCCESS',
  GET_NEWS_BY_NAME_ERROR: 'news/GET_NEWS_BY_NAME_ERROR',

  POST_NEWS_ITEM_COMMENT_REQUEST: 'news/POST_NEWS_ITEM_COMMENT_REQUEST',
  POST_NEWS_ITEM_COMMENT_SUCCESS: 'news/POST_NEWS_ITEM_COMMENT_SUCCESS',
  POST_NEWS_ITEM_COMMENT_ERROR: 'news/POST_NEWS_ITEM_COMMENT_ERROR',

  POST_NEWS_ITEM_LIKE_REQUEST: 'news/POST_NEWS_ITEM_LIKE_REQUEST',
  POST_NEWS_ITEM_LIKE_SUCCESS: 'news/POST_NEWS_ITEM_LIKE_SUCCESS',
  POST_NEWS_ITEM_LIKE_ERROR: 'news/POST_NEWS_ITEM_LIKE_ERROR',

  POST_NEWS_ITEM_DISLIKE_REQUEST: 'news/POST_NEWS_ITEM_DISLIKE_REQUEST',
  POST_NEWS_ITEM_DISLIKE_SUCCESS: 'news/POST_NEWS_ITEM_DISLIKE_SUCCESS',
  POST_NEWS_ITEM_DISLIKE_ERROR: 'news/POST_NEWS_ITEM_DISLIKE_ERROR',

  POST_NEWS_ITEM_COMMENT_LIKE_REQUEST:
    'news/POST_NEWS_ITEM_COMMENT_LIKE_REQUEST',
  POST_NEWS_ITEM_COMMENT_LIKE_SUCCESS:
    'news/POST_NEWS_ITEM_COMMENT_LIKE_SUCCESS',
  POST_NEWS_ITEM_COMMENT_LIKE_ERROR: 'news/POST_NEWS_ITEM_COMMENT_LIKE_ERROR',

  POST_NEWS_ITEM_COMMENT_DISLIKE_REQUEST:
    'news/POST_NEWS_ITEM_COMMENT_DISLIKE_REQUEST',
  POST_NEWS_ITEM_COMMENT_DISLIKE_SUCCESS:
    'news/POST_NEWS_ITEM_COMMENT_DISLIKE_SUCCESS',
  POST_NEWS_ITEM_COMMENT_DISLIKE_ERROR:
    'news/POST_NEWS_ITEM_COMMENT_DISLIKE_ERROR',

  DELETE_NEWS_ITEM_COMMENT_REQUEST: 'news/DELETE_NEWS_ITEM_COMMENT_REQUEST',
  DELETE_NEWS_ITEM_COMMENT_SUCCESS: 'news/DELETE_NEWS_ITEM_COMMENT_SUCCESS',
  DELETE_NEWS_ITEM_COMMENT_ERROR: 'news/DELETE_NEWS_ITEM_COMMENT_ERROR',

  CLEAR_PAGE_DATA_NEWS_ITEM: 'news/CLEAR_PAGE_DATA_NEWS_ITEM',
};
export default types;
