import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadingStart, loadingStop } from 'redux/store/loading/loadingActions';
import { globalLoaderTypes } from 'views/component/Loaders/GlobalLoader/GlobalLoader.config';

import { Body } from './Body';
import { Header } from './Header';

import s from './styles.module.scss';

const AboutUs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingStart(globalLoaderTypes.tertiary));
    let timer = setTimeout(() => dispatch(loadingStop()), 650);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={s.aboutUs}>
      <div className={s.container}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default AboutUs;
