import React from 'react';

import promo from 'assets/videos/promo.mp4';
import promoPhone from 'assets/videos/promo-phone.mp4';
import { useWindowWidth } from 'utilites';

import './styles.scss';

export const PromoVideo = ({ setIsPromo }) => {
  const width = useWindowWidth();

  const handleFinishedVideo = () => {
    sessionStorage.setItem('promo', 'finished');
    setIsPromo('finished');
  };

  return (
    <div className="gmt-promo">
      <video
        onEnded={handleFinishedVideo}
        className="gmt-promo__video"
        preload="auto"
        playsInline
        autoPlay
        muted
      >
        <source src={width > 833 ? promo : promoPhone} type="video/mp4" />
      </video>
    </div>
  );
};
