import { reset } from 'redux-form';

import * as api from '../../../api/auth-service/auth-service';
import * as userApi from '../../../api/user-service/user-service';
import book from '../../../constants/book';
import customHistory from '../../../utilites/customHistory';
import { openNotificationModal } from '../notification/notificationActions';
import { clearProfileAfterLogout } from '../profile/profileActions';
import { store } from '../store';
import * as sessionActions from './sessionActions';

export const loginUser = data => dispatch => {
  dispatch(sessionActions.loginRequest());

  return api
    .login(data)
    .then(response => {
      const {
        token,
        user: { isConfirmed, _id: userId },
      } = response;

      if (isConfirmed && token) {
        dispatch(sessionActions.loginSuccessConfirm(token));
      } else {
        dispatch(sessionActions.loginSuccess());
        dispatch(
          openNotificationModal({
            message: 'notification_login',
            notificationType: 'auth-signin',
          }),
        );
      }

      dispatch(reset('login'));
      dispatch(sessionActions.saveUserId(userId));
      localStorage.setItem('session', JSON.stringify(response));
      dispatch(sessionActions.tokenExpirationStatus());
    })
    .catch(error => {
      dispatch(sessionActions.loginError(error));

      throw error;
    });
};

export const reSendConfirmCode = () => dispatch => {
  dispatch(sessionActions.reSendConfirmCodeRequest());

  return api
    .sendConfirmCode()
    .then(() => {
      dispatch(sessionActions.reSendConfirmCodeSuccess());
      dispatch(
        openNotificationModal({
          message: 'notification_re-send_confirm',
          notificationType: 'auth-confirm',
        }),
      );
    })
    .catch(error => {
      dispatch(
        openNotificationModal({
          message: 'notification_re-send_confirm_error',
          notificationType: 'auth-confirm',
        }),
      );
      dispatch(sessionActions.reSendConfirmCodeError(error));
    });
};

export const confirmUser = code => dispatch => {
  dispatch(sessionActions.confirmRequest());

  return api
    .confirmUser(code)
    .then(response => {
      dispatch(sessionActions.confirmRequest());

      const sessionJson = localStorage.getItem('session');
      const sessionObj = JSON.parse(sessionJson);
      const newSessionObj = { ...sessionObj, user: response };

      localStorage.setItem('session', JSON.stringify(newSessionObj));

      const {
        token,
        user: { isConfirmed },
      } = newSessionObj;

      if (isConfirmed && token) dispatch(sessionActions.confirmSuccess(token));

      dispatch(sessionActions.tokenExpirationStatus());

      dispatch(
        openNotificationModal({
          message: 'notification_confirm',
          notificationType: 'auth-confirm',
        }),
      );
    })
    .catch(error => {
      dispatch(
        openNotificationModal({
          message: error.message,
          notificationType: 'auth-unauthorized',
        }),
      );

      dispatch(sessionActions.confirmError(error));
    });
};

export const registrationUser = data => dispatch => {
  dispatch(sessionActions.signupRequest());

  return api
    .signup(data)
    .then(response => {
      const {
        token,
        user: { isConfirmed, _id: userId },
      } = response;

      if (isConfirmed && token) {
        dispatch(sessionActions.signupSuccessConfirm(token));
      } else {
        dispatch(sessionActions.signupSuccess());
        dispatch(
          openNotificationModal({
            message: 'notification_registration',
            notificationType: 'auth-signup',
          }),
        );
      }

      localStorage.setItem('session', JSON.stringify(response));
      dispatch(sessionActions.saveUserId(userId));
      dispatch(sessionActions.tokenExpirationStatus());

      dispatch(reset('registration'));
    })
    .catch(error => {
      dispatch(sessionActions.signupError(error));

      throw error;
    });
};

export const forgotPassword = body => dispatch => {
  dispatch(sessionActions.forgotRequest());

  return api
    .forgotPassword(body)
    .then(() => {
      dispatch(sessionActions.forgotSuccess());
      dispatch(reset('forgot'));
      dispatch(
        openNotificationModal({
          message: 'notification_recovery',
          notificationType: 'auth-forgot',
        }),
      );

      return true;
    })
    .catch(error => {
      dispatch(sessionActions.forgotError());

      throw error;
    });
};

export const resetPassword = (token, password) => dispatch => {
  dispatch(sessionActions.resetRequest());

  return api
    .resetPassword(token, password)
    .then(() => {
      dispatch(sessionActions.resetSuccess());
      dispatch(
        openNotificationModal({
          message: 'notification_reset',
          notificationType: 'auth-reset',
        }),
      );
      dispatch(reset('reset'));

      return true;
    })
    .catch(error => {
      dispatch(sessionActions.resetError(error));

      throw error;
    });
};

export const getCurrentUser = () => dispatch => {
  const session = localStorage.getItem('session');

  if (!session) return;

  const {
    expirationDate,
    token,
    user: { isConfirmed = true, _id: userId },
  } = JSON.parse(session);

  const expiration = new Date(expirationDate);
  const current = new Date();

  const isActiveToken = expiration - current > 0;

  dispatch(sessionActions.getCurrentStart());

  if (isActiveToken && isConfirmed) {
    dispatch(sessionActions.getCurrentSuccessConfirm(token));
  } else if (isActiveToken && !isConfirmed) {
    dispatch(sessionActions.getCurrentSuccess());
  }

  dispatch(sessionActions.saveUserId(userId));
  dispatch(sessionActions.tokenExpirationStatus());

  if (!isActiveToken) {
    dispatch(refreshTokenAuth());
  }
};

export const refreshTokenAuth = () => dispatch => {
  const session = localStorage.getItem('session');

  if (!session) return null;

  const { refreshToken } = JSON.parse(session);

  return api
    .updateToken({ refreshToken: refreshToken })
    .then(response => {
      const sessionJson = localStorage.getItem('session');
      const sessionObj = JSON.parse(sessionJson);

      const newSessionObject = { ...sessionObj, ...response };
      localStorage.setItem('session', JSON.stringify(newSessionObject));

      const { isConfirmed, token } = newSessionObject;

      isConfirmed && token
        ? dispatch(sessionActions.getCurrentSuccessConfirm(token))
        : dispatch(sessionActions.getCurrentSuccess());

      dispatch(sessionActions.tokenExpirationStatus());

      return newSessionObject;
    })
    .catch(() => {
      customHistory.push(book.login);
      dispatch(logOut('error'));
    });
};

export const logOut = type => dispatch => {
  dispatch(sessionActions.logOutStart());
  clearTimeout(window.updateTokenTimeoutId);
  dispatch(clearProfileAfterLogout());
  localStorage.removeItem('session');
  type === 'error' &&
    dispatch(
      openNotificationModal({
        message: 'notification_unauthorized',
        notificationType: 'auth-unauthorized',
      }),
    );

  api.logoutSocialAuth();

  dispatch(sessionActions.logOutSuccess());
};

export const checkAuthUser = async () => {
  const session = localStorage.getItem('session');

  if (session) {
    return store.dispatch(getCurrentUser());
  }

  try {
    const socialAuth = await api.checkAuth();

    const newObj = {
      ...socialAuth,
    };

    localStorage.setItem('session', JSON.stringify(newObj));

    const userInfo = await userApi.getUser();

    const extendedObj = {
      ...socialAuth,
      user: {
        ...userInfo,
      },
    };

    localStorage.setItem('session', JSON.stringify(extendedObj));
  } finally {
    store.dispatch(getCurrentUser());
  }
};
