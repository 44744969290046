import types from './types';
const initialState = {
  allCategory: [],
};

export const categorySelector = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_CATEGORY_SELECTOR:
      return { ...state, allCategory: payload };
    default:
      return state;
  }
};
