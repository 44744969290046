import * as apiEvent from '../../../api/event-service/event-service';
import { globalLoaderTypes } from '../../../views/component/Loaders/GlobalLoader/GlobalLoader.config';
import { loadingStart, loadingStop } from '../loading/loadingActions';
import * as tournamentsActions from './tournamentsActions';

export const fetchTournamentsList =
  (offset, limit, startLoading) => dispatch => {
    dispatch(tournamentsActions.getTournamentsRequest());
    if (startLoading) dispatch(loadingStart(globalLoaderTypes.secondary));

    return apiEvent
      .getTournamentList({ offset, limit })
      .then(items => dispatch(tournamentsActions.getTournamentsSuccess(items)))
      .catch(error => dispatch(tournamentsActions.getTournamentsError(error)))
      .finally(() => {
        if (startLoading) {
          dispatch(loadingStart(globalLoaderTypes.tertiary));
          setTimeout(() => dispatch(loadingStop()), 650);
        }
      });
  };

export const fetchTournamentById = id => dispatch => {
  dispatch(tournamentsActions.getTournamentByIdRequest());

  return apiEvent
    .getTournamentById(id)
    .then(items => dispatch(tournamentsActions.getTournamentByIdSuccess(items)))
    .catch(error => dispatch(tournamentsActions.getTournamentByIdError(error)));
};
