import { useCallback, useMemo } from 'react';

import { SharedClassContext } from './SharedClassContext';

const baseClassPrefix = 'gmt-';

export const SharedClassProvider = props => {
  const { children, rootPrefix } = props || {};

  const rootClass = `${baseClassPrefix}${rootPrefix}`;

  const appendClass = useCallback(
    childClass => {
      if (Array.isArray(childClass)) {
        return childClass.map(child => `${rootClass}${child}`).join(' ');
      }

      return `${rootClass}${childClass}`;
    },
    [rootClass],
  );

  const value = useMemo(
    () => ({
      rootClass,
      appendClass,
    }),
    [rootPrefix, appendClass],
  );

  return (
    <SharedClassContext.Provider value={value}>
      {children}
    </SharedClassContext.Provider>
  );
};
