import { combineReducers } from 'redux';

import types from './photoTypes';

const initialState = {
  gallery: {
    items: [],
  },
  album: [],
  albumItem: {
    loader: true,
  },
  loader: true,
  error: '',
};

const data = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_ALBUMS_SUCCESS:
      return { ...state, gallery: payload, loader: false };

    case types.GET_ALBUM_BY_ID_SUCCESS:
      return { ...state, album: payload };

    case types.GET_PHOTO_BY_ID_SUCCESS:
      return { ...state, albumItem: { ...payload, loader: false } };

    case types.POST_ALBUM_ITEM_LIKE_SUCCESS:
      return {
        ...state,
        albumItem: {
          ...state.albumItem,
          likesCount: payload.likesCount,
          dislikesCount: payload.dislikesCount,
        },
      };

    case types.POST_ALBUM_ITEM_DISLIKE_SUCCESS:
      return {
        ...state,
        albumItem: {
          ...state.albumItem,
          likesCount: payload.likesCount,
          dislikesCount: payload.dislikesCount,
        },
      };

    case types.POST_ALBUM_ITEM_COMMENT_SUCCESS:
      return {
        ...state,
        albumItem: {
          ...state.albumItem,
          comments: [...state.albumItem.comments, payload],
          commentsCount: state.albumItem.commentsCount + 1,
        },
      };

    case types.DELETE_ALBUM_ITEM_COMMENT_SUCCESS:
      return {
        ...state,
        albumItem: {
          ...state.albumItem,
          comments: state.albumItem.comments.filter(
            ({ _id }) => _id !== payload,
          ),
          commentsCount: state.albumItem.commentsCount - 1,
        },
      };

    case types.POST_ALBUM_ITEM_COMMENT_LIKE_SUCCESS:
      return {
        ...state,
        albumItem: {
          ...state.albumItem,
          comments: payload,
        },
      };

    case types.POST_ALBUM_ITEM_COMMENT_DISLIKE_SUCCESS:
      return {
        ...state,
        albumItem: {
          ...state.albumItem,
          comments: payload,
        },
      };

    case types.GET_ALBUMS_REQUEST:
      return { ...state, loader: true };

    case types.GET_ALBUMS_ERROR:
      return { ...state, error: payload, loader: false };

    case types.GET_PHOTO_BY_ID_ERROR:
      return { ...state, albumItem: { ...state.albumItem, loader: false } };

    case types.GET_PHOTO_BY_ID_REQUEST:
      return { ...state, albumItem: { ...state.albumItem, loader: true } };

    default:
      return state;
  }
};

// const loading = (state = false, { type }) => {
//   switch (type) {
//     case types.GET_PHOTO_BY_ID_REQUEST:
//     case types.GET_ALBUMS_REQUEST:
//     case types.GET_ALBUM_BY_ID_REQUEST:
//       return true;
//     case types.GET_PHOTO_BY_ID_SUCCESS:
//     case types.GET_PHOTO_BY_ID_ERROR:
//     case types.GET_ALBUMS_SUCCESS:
//     case types.GET_ALBUMS_ERROR:
//     case types.GET_ALBUM_BY_ID_SUCCESS:
//     case types.GET_ALBUM_BY_ID_ERROR:
//       return false;
//     default:
//       return state;
//   }
// };

export const photoReducer = combineReducers({
  data,
  // loading,
});
