import types from './playerTypes';

export const getPlayersRequest = () => ({
  type: types.GET_PLAYERS_REQUEST,
});

export const getPlayersSuccess = (data = {}) => ({
  type: types.GET_PLAYERS_SUCCESS,
  payload: data,
});

export const getPlayersError = (error = '') => ({
  type: types.GET_PLAYERS_ERROR,
  payload: error,
});

export const getRosterPlayersRequest = () => ({
  type: types.GET_ROSTER_PLAYERS_REQUEST,
});

export const getRosterPlayersSuccess = (data = {}) => ({
  type: types.GET_ROSTER_PLAYERS_SUCCESS,
  payload: data,
});

export const getRosterPlayersError = (error = '') => ({
  type: types.GET_ROSTER_PLAYERS_ERROR,
  payload: error,
});

export const getPlayerByIdRequest = () => ({
  type: types.GET_PLAYER_BY_ID_REQUEST,
});

export const getPlayerByIdSuccess = (data = {}) => ({
  type: types.GET_PLAYER_BY_ID_SUCCESS,
  payload: data,
});

export const getPlayerByIdError = (error = '') => ({
  type: types.GET_PLAYER_BY_ID_ERROR,
  payload: error,
});

export const clearPageDataPlayerItem = () => ({
  type: types.CLEAR_PAGE_DATA_PLAYER_ITEM,
});

export const getPlayerByNameRequest = () => ({
  type: types.GET_PLAYER_BY_NAME_REQUEST,
});

export const getPlayerByNameSuccess = (data = {}) => ({
  type: types.GET_PLAYER_BY_NAME_SUCCESS,
  payload: data,
});

export const getPlayerByNameError = (error = '') => ({
  type: types.GET_PLAYER_BY_NAME_ERROR,
  payload: error,
});
