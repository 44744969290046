import { types } from './achievementsTypes';

const initialState = {
  items: [],
  count: 0,
};

export const achievementsReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case types.GET_ACHIEVEMENTS_SUCCESS:
      return payload;

    default:
      return state;
  }
};
