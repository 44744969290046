import { reset } from 'redux-form';

import * as api from '../../../api/news-service/news-service';
import { changedCommentCharacteristics } from '../../../utilites/updateComments';
import { globalLoaderTypes } from '../../../views/component/Loaders/GlobalLoader/GlobalLoader.config';
import { languageSelector } from '../../selectors';
import { newsItemCommentsSelector } from '../../selectors';
import { loadingStart, loadingStop } from '../loading/loadingActions';
import { notificationModal } from '../notification/notificationOperations';
import * as newsActions from './newsActions';

export const fetchNewsList =
  (offset, limit, tag, startLoading) => (dispatch, getState) => {
    const lng = languageSelector(getState());
    dispatch(newsActions.getNewsRequest());
    if (startLoading) dispatch(loadingStart(globalLoaderTypes.secondary));

    return api
      .getNewsAll(offset, limit, tag, lng)
      .then(response => dispatch(newsActions.getNewsSuccess(response)))
      .catch(error => dispatch(newsActions.getNewsError(error)))
      .finally(() => {
        if (startLoading) {
          dispatch(loadingStart(globalLoaderTypes.tertiary));
          setTimeout(() => dispatch(loadingStop()), 650);
        }
      });
  };

export const fetchNewsItemById = id => dispatch => {
  dispatch(newsActions.getNewsByIdRequest());
  dispatch(loadingStart(globalLoaderTypes.secondary));

  return api
    .getNewsById(id)
    .then(response => dispatch(newsActions.getNewsByIdSuccess(response)))
    .catch(error => dispatch(newsActions.getNewsByIdError(error)))
    .finally(() => {
      dispatch(loadingStart(globalLoaderTypes.tertiary));
      setTimeout(() => dispatch(loadingStop()), 650);
    });
};

export const fetchNewsItemByName = name => dispatch => {
  dispatch(newsActions.getNewsByNameRequest());
  dispatch(loadingStart(globalLoaderTypes.secondary));

  return api
    .getNewsByName(name)
    .then(response => dispatch(newsActions.getNewsByNameSuccess(response)))
    .catch(error => dispatch(newsActions.getNewsByNameError(error)))
    .finally(() => {
      dispatch(loadingStart(globalLoaderTypes.tertiary));
      setTimeout(() => dispatch(loadingStop()), 650);
    });
};

export const deleteNewsItemComment = (commentId, newsId) => dispatch => {
  dispatch(newsActions.deleteNewsItemCommentRequest());

  return api
    .deleteNewsItemComment(newsId, commentId)
    .then(() => {
      dispatch(newsActions.deleteNewsItemCommentSuccess(commentId));
    })
    .catch(error => {
      dispatch(
        notificationModal({
          message: 'notification_remove-comment-error',
        }),
      );
      dispatch(newsActions.deleteNewsItemCommentError(error));
    });
};

export const postNewsItemLike = id => dispatch => {
  dispatch(newsActions.postNewsItemLikeRequest());

  return api
    .postNewsItemLike(id)
    .then(response => {
      dispatch(newsActions.postNewsItemLikeSuccess(response[0]));
    })
    .catch(error => {
      dispatch(
        notificationModal({
          message: 'notification_reaction',
          notificationType: 'reaction',
        }),
      );
      dispatch(newsActions.postNewsItemLikeError(error));
    });
};

export const postNewsItemDislike = id => dispatch => {
  dispatch(newsActions.postNewsItemDislikeRequest());

  return api
    .postNewsItemDislike(id)
    .then(response =>
      dispatch(newsActions.postNewsItemDislikeSuccess(response[0])),
    )
    .catch(error => {
      dispatch(
        notificationModal({
          message: 'notification_reaction',
          notificationType: 'reaction',
        }),
      );
      dispatch(newsActions.postNewsItemDislikeError(error));
    });
};

export const postNewsItemComment = (id, body) => dispatch => {
  dispatch(newsActions.postNewsItemCommentRequest());

  return api
    .postNewsItemComment(id, body)
    .then(response => {
      dispatch(reset('comment'));

      dispatch(newsActions.postNewsItemCommentSuccess(response));
    })
    .catch(error => {
      dispatch(newsActions.postNewsItemCommentError(error));
    });
};

export const postNewsItemCommentLike = id => (dispatch, getState) => {
  const commentsList = newsItemCommentsSelector(getState());

  dispatch(newsActions.postNewsItemCommentLikeRequest());

  return api
    .postNewsItemCommentLike(id)
    .then(response => {
      const updatedComment = response[0];
      const newCommentsArray = changedCommentCharacteristics(
        commentsList,
        updatedComment,
      );
      dispatch(newsActions.postNewsItemCommentLikeSuccess(newCommentsArray));
    })
    .catch(error => {
      dispatch(
        notificationModal({
          message: 'notification_reaction',
          notificationType: 'reaction',
        }),
      );
      dispatch(newsActions.postNewsItemCommentLikeError(error));
    });
};

export const postNewsItemCommentDislike = id => (dispatch, getState) => {
  dispatch(newsActions.postNewsItemCommentDislikeRequest());
  const commentsList = newsItemCommentsSelector(getState());

  return api
    .postNewsItemCommentDislike(id)
    .then(response => {
      const updatedComment = response[0];
      const newCommentsArray = changedCommentCharacteristics(
        commentsList,
        updatedComment,
      );
      dispatch(newsActions.postNewsItemCommentDislikeSuccess(newCommentsArray));
    })
    .catch(error => {
      dispatch(
        notificationModal({
          message: 'notification_reaction',
          notificationType: 'reaction',
        }),
      );
      dispatch(newsActions.postNewsItemCommentDislikeError(error));
    });
};

export const clearPageDataNewsItem = () => dispatch =>
  dispatch(newsActions.clearPageDataNewsItem());
