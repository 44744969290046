import React from 'react';

import { VenueCard } from 'views/component';

import { venuesCards } from '../venues-cards';

import s from './styles.module.scss';

export const Cards = () => {
  const mapCards = venuesCards.map(venue => (
    <div key={venue.key} className={s.cardWrapper}>
      <VenueCard {...venue} />
    </div>
  ));

  return <div className={s.container}>{mapCards}</div>;
};
