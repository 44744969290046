import { types } from './bannerTypes';

const initialState = [];

export const bannerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_BANNERS_SUCCESS:
      return payload;

    default:
      return state;
  }
};
