const types = {
  GET_VIDEO_REQUEST: 'video/GET_VIDEO_REQUEST',
  GET_VIDEO_LIST_SUCCESS: 'video/GET_VIDEO_LIST_SUCCESS',
  GET_VIDEO_LIST_ERROR: 'video/GET_VIDEO_LIST_ERROR',
  GET_VIDEO_ERROR: 'video/GET_VIDEO_ERROR',

  EDIT_VIDEO_COMMENT: 'video/EDIT_VIDEO_COMMENT',
  POST_VIDEO_COMMENT: 'video/POST_VIDEO_COMMENT',
  GET_VIDEO_ITEM_REQUEST: 'video/GET_VIDEO_ITEM_REQUEST',
  SAVE_VIDEO_TYPE_AND_URL: 'video/SAVE_VIDEO_TYPE_AND_URL',
  SET_USER_ACTIONS: 'video/SET_USER_ACTIONS',
  CLEAR_PAGE_DATA_VIDEO_ITEM: 'video/CLEAR_PAGE_DATA_VIDEO_ITEM',

  GET_RELATED_VIDEO_LIST_REQUEST: 'video/GET_RELATED_VIDEO_LIST_REQUEST',
  GET_RELATED_VIDEO_LIST_SUCCESS: 'video/GET_RELATED_VIDEO_LIST_SUCCESS',
  GET_RELATED_VIDEO_LIST_ERROR: 'video/GET_RELATED_VIDEO_LIST_ERROR',

  DELETE_VIDEO_COMMENT_REQUEST: 'video/DELETE_VIDEO_COMMENT_REQUEST',
  DELETE_VIDEO_COMMENT_SUCCESS: 'video/DELETE_VIDEO_COMMENT_SUCCESS',
  DELETE_VIDEO_COMMENT_ERROR: 'video/DELETE_VIDEO_COMMENT_ERROR',

  CLEAR_RELATED_VIDEO_LIST: 'video/CLEAR_RELATED_VIDEO_LIST',
};
export default types;
