import React from 'react';
import { withTranslation } from 'react-i18next';

import cn from 'classnames';

import BurgerNavItem from '../BurgerNavItem/BurgerNavItem';

import './BurgerNav.styles.scss';

const BurgerNav = ({ t, navItems, isOpen, onClick }) => {
  return (
    <div
      className={cn('gmt-menu', {
        'gmt-menu_open': isOpen,
      })}
    >
      <div className="gmt-menu__container">
        <ul className="gmt-menu__list">
          {navItems.map(({ title, ref, isRedirect }, index) => (
            <li key={`${title}_${index}`} className="gmt-menu__item">
              <BurgerNavItem
                title={t(title)}
                href={ref}
                isRedirect={isRedirect}
                onClick={onClick}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(BurgerNav);
