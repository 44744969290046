import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { newsLoaderSelector, newsSelector } from 'redux/selectors';
import { fetchNewsList } from 'redux/store/news/newsOperations';
import { LoadMore } from 'views/component/buttons';

import { Cards } from './Cards';
import { Header } from './Header';

import s from './styles.module.scss';

const News = () => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(4);

  const { count } = useSelector(newsSelector);
  const isLoading = useSelector(newsLoaderSelector);

  useEffect(() => {
    dispatch(fetchNewsList(0, limit, '', true));
  }, []);

  const handleClickLoadMore = () => {
    const updatedLimit = limit + 4;
    dispatch(fetchNewsList(0, updatedLimit, '', false));
    setLimit(updatedLimit);
  };

  return (
    <div className={s.gmtNews}>
      <Header />
      <Cards />
      <LoadMore
        canShow={count > limit}
        isLoading={isLoading}
        onClick={handleClickLoadMore}
      />
    </div>
  );
};

export default News;
