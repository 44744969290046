import React from 'react';

import defaultImage from 'assets/images/lazy-image-min.png';
import { useProgressiveImg } from 'utilites/';
import { Loader } from 'views/component';

import './styles.scss';

export const ProgressiveImage = props => {
  const {
    options = {},
    image = '',
    alt = '',
    isLoading = false,
    loaderSize = 34,
  } = props;

  const src = useProgressiveImg(defaultImage, image);

  if (src === defaultImage || isLoading) {
    return (
      <div {...options}>
        <Loader size={loaderSize} />
      </div>
    );
  }

  return <img src={src} alt={alt} {...options} />;
};
