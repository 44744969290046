import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({
  component: Component,
  redirectPath,
  ...rest
}) => {
  const isAuth = localStorage.getItem('session');

  return (
    <Route
      render={props => {
        return isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: props.location },
            }}
          />
        );
      }}
      {...rest}
    />
  );
};
