import types from './playerTypes';

const initialState = {
  allPlayers: {
    items: [],
  },
  rosterPlayers: {
    random: '',
    items: [],
    count: 0,
  },
  playerItem: {
    avatar: '',
    name: {
      nick: '',
      first: '',
      last: '',
    },
    dateInGMT: '',
    birthday: '',
    games: [],
    type: '',
    facebook: '',
    youtube: '',
    instagram: '',
    twitter: '',
    vk: '',
  },
  loader: true,
  error: '',
};

export const playersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PLAYERS_SUCCESS:
      return { ...state, allPlayers: payload, loader: false };

    case types.GET_ROSTER_PLAYERS_SUCCESS:
      return { ...state, rosterPlayers: payload, loader: false };

    case types.GET_PLAYER_BY_NAME_SUCCESS:
    case types.GET_PLAYER_BY_ID_SUCCESS:
      return { ...state, playerItem: payload };

    case types.CLEAR_PAGE_DATA_PLAYER_ITEM:
      return { ...state, playerItem: initialState.playerItem };

    case types.GET_ROSTER_PLAYERS_ERROR:
    case types.GET_PLAYERS_ERROR:
      return { ...state, error: payload, loader: false };

    case types.GET_ROSTER_PLAYERS_REQUEST:
    case types.GET_PLAYERS_REQUEST:
      return { ...state, loader: true };
    default:
      return state;
  }
};
