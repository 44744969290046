import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { newsItemSelector } from 'redux/selectors';
import {
  clearPageDataNewsItem,
  fetchNewsItemByName,
} from 'redux/store/news/newsOperations';
import { Loader, NewsCard } from 'views/component';

import s from './styles.module.scss';

const NewsItem = () => {
  const news = useSelector(newsItemSelector);
  const dispatch = useDispatch();
  const { name } = useParams();

  useEffect(() => {
    dispatch(fetchNewsItemByName(name));
    return () => dispatch(clearPageDataNewsItem());
  }, [name]);

  if (!news) {
    return <Loader />;
  }

  return (
    <div className={s.newsItem}>
      <div className={s.container}>
        <NewsCard {...news} image={news['titleImage_DESKTOP']} type="article" />
      </div>
    </div>
  );
};

export default NewsItem;
