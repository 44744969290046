import React, { useEffect, useState } from 'react';

import { getLink } from 'api/link-service/link-service';
import { LINK_MODEL } from 'models/link.model';

import BurgerMenu from './BurgerMenu/BurgerMenu';
import { headerConfig } from './Header.config';
import LandingHeader from './LandingHeader/LandingHeader';

export const Header = ({ className }) => {
  const [sizeHeader, setSizeHeader] = useState(false);

  const [shopSubmenu, setShopSubmenu] = useState([]);

  const resizeHeader = () => {
    setSizeHeader(true);
    setTimeout(() => {
      setSizeHeader(false);
    }, 0);
  };

  useEffect(() => {}, [sizeHeader]);

  // Get shop links
  useEffect(() => {
    const updateShopSubmenu = shopSubItems => {
      setShopSubmenu(shopSubItems.items);
    };

    const handleLinkError = error => {
      setShopSubmenu([
        {
          [LINK_MODEL.id]: Math.random(100000),
          [LINK_MODEL.name]: 'Network error!',
          [LINK_MODEL.url]: '',
        },
      ]);
    };

    getLink().then(updateShopSubmenu).catch(handleLinkError);
  }, []);

  return (
    <>
      <BurgerMenu resizeHeader={resizeHeader} navItems={headerConfig} />
      <LandingHeader
        className={className}
        resizeHeader={resizeHeader}
        navItems={headerConfig}
        shopSubmenu={shopSubmenu}
      />
    </>
  );
};
