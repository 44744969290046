import types from './gamesTypes';

const initialState = {
  allGames: {
    items: [],
  },
  gameItem: {
    firstTeamPlayers: [],
    secondTeamPlayers: [],
    information: {},
  },
  loader: true,
};

export const gamesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_GAMES_SUCCESS:
      return { ...state, allGames: payload, loader: false };
    case types.GET_GAME_BY_ID_SUCCESS:
      return {
        ...state,
        gameItem: {
          ...state.gameItem,
          information: payload,
        },
      };
    case types.GET_GAME_PLAYERS_FIRST_TEAM_SUCCESS:
      return {
        ...state,
        gameItem: {
          ...state.gameItem,
          firstTeamPlayers: payload,
        },
      };

    case types.GET_GAME_PLAYERS_SECOND_TEAM_SUCCESS:
      return {
        ...state,
        gameItem: {
          ...state.gameItem,
          secondTeamPlayers: payload,
        },
      };

    case types.POST_GAME_ITEM_LIKE_SUCCESS:
    case types.POST_GAME_ITEM_DISLIKE_SUCCESS:
      return {
        ...state,
        gameItem: {
          ...state.gameItem,
          information: {
            ...state.gameItem.information,
            likesCount: payload.likesCount,
            dislikesCount: payload.dislikesCount,
          },
        },
      };
    case types.POST_GAME_ITEM_COMMENT_SUCCESS:
      return {
        ...state,
        gameItem: {
          ...state.gameItem,
          information: {
            ...state.gameItem.information,
            commentsCount: state.gameItem.information.commentsCount + 1,
            comments: [...state.gameItem.information.comments, payload],
          },
        },
      };
    case types.POST_GAME_ITEM_COMMENT_LIKE_SUCCESS:
    case types.POST_GAME_ITEM_COMMENT_DISLIKE_SUCCESS:
      return {
        ...state,
        gameItem: {
          ...state.gameItem,
          information: {
            ...state.gameItem.information,
            comments: payload,
          },
        },
      };
    case types.DELETE_GAME_ITEM_COMMENT_SUCCESS:
      return {
        ...state,
        gameItem: {
          ...state.gameItem,
          information: {
            ...state.gameItem.information,
            commentsCount: state.gameItem.information.commentsCount - 1,
            comments: state.gameItem.information.comments.filter(
              ({ _id }) => _id !== payload,
            ),
          },
        },
      };

    //errors

    case types.GET_GAMES_ERROR:
      return { ...state, error: payload, loader: false };

    //loader

    case types.GET_GAMES_REQUEST:
      return { ...state, loader: true };

    default:
      return state;
  }
};
