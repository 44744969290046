import types from './streamTypes';

const initialState = {
  allStream: {
    items: [],
  },
};

export const streamReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_STREAM_SUCCESS:
      return { ...state, allStream: payload };

    default:
      return state;
  }
};
