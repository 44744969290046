import Airbus from 'assets/images/company-logos/Airbus_Logo_2017.svg';
import CocaCola from 'assets/images/company-logos/Coca-Cola_logo.svg';
import Intel from 'assets/images/company-logos/Intel_logo_(2006-2020).svg';
import Mobil from 'assets/images/company-logos/Mobil_logo.svg';
import Dew from 'assets/images/company-logos/Mountain_Dew_logo.svg';
import RedBull from 'assets/images/company-logos/Red_Bull.svg';
import TMobile from 'assets/images/company-logos/T-Mobile_logo.svg';
import Xfinity from 'assets/images/company-logos/Xfinity_logo.svg';
import FirstImage from 'assets/images/venues/1.png';
import SecondImage from 'assets/images/venues/2.png';
import ThirdImage from 'assets/images/venues/3.png';
import FourthImage from 'assets/images/venues/4.png';

export const agencyCards = [
  {
    key: '1',
    title: 'Title',
    description:
      'Fusce eget tellus sit amet sem tempor malesuada. Vivamus vel quam venenatis, scelerisque lorem a, maximus lorem. Quisque in tempor massa, vitae egestas purus. Praesent dictum ipsum',
    location:
      'Mimar Hayrettin, Paşa Caddesi & Doğramacı Sokak No:21, 34126 Fatih/İstanbul,',
    images: [
      {
        key: '1',
        image: FirstImage,
      },
      {
        key: '2',
        image: SecondImage,
      },
      {
        key: '3',
        image: ThirdImage,
      },
      {
        key: '4',
        image: FourthImage,
      },
      {
        key: '5',
        image: SecondImage,
      },
    ],
    sponsors: [
      {
        key: '1',
        logo: CocaCola,
      },
      {
        key: '2',
        logo: Mobil,
      },
      {
        key: '3',
        logo: Xfinity,
      },
      {
        key: '4',
        logo: RedBull,
      },
      {
        key: '5',
        logo: Dew,
      },
      {
        key: '6',
        logo: Airbus,
      },
      {
        key: '7',
        logo: Intel,
      },
      {
        key: '8',
        logo: TMobile,
      },
    ],
  },
  {
    key: '2',
    title: 'Title',
    description:
      'Fusce eget tellus sit amet sem tempor malesuada. Vivamus vel quam venenatis, scelerisque lorem a, maximus lorem. Quisque in tempor massa, vitae egestas purus. Praesent dictum ipsum',
    location:
      'Mimar Hayrettin, Paşa Caddesi & Doğramacı Sokak No:21, 34126 Fatih/İstanbul,',
    images: [
      {
        key: '1',
        image: FirstImage,
      },
      {
        key: '2',
        image: SecondImage,
      },
      {
        key: '3',
        image: ThirdImage,
      },
      {
        key: '4',
        image: FourthImage,
      },
      {
        key: '5',
        image: SecondImage,
      },
    ],
    sponsors: [
      {
        key: '1',
        logo: CocaCola,
      },
      {
        key: '2',
        logo: Mobil,
      },
      {
        key: '3',
        logo: Xfinity,
      },
      {
        key: '4',
        logo: RedBull,
      },
      {
        key: '5',
        logo: Dew,
      },
      {
        key: '6',
        logo: Airbus,
      },
      {
        key: '7',
        logo: Intel,
      },
      {
        key: '8',
        logo: TMobile,
      },
    ],
  },
  {
    key: '3',
    title: 'Title',
    description:
      'Fusce eget tellus sit amet sem tempor malesuada. Vivamus vel quam venenatis, scelerisque lorem a, maximus lorem. Quisque in tempor massa, vitae egestas purus. Praesent dictum ipsum',
    location:
      'Mimar Hayrettin, Paşa Caddesi & Doğramacı Sokak No:21, 34126 Fatih/İstanbul,',
    images: [
      {
        key: '1',
        image: FirstImage,
      },
      {
        key: '2',
        image: SecondImage,
      },
      {
        key: '3',
        image: ThirdImage,
      },
      {
        key: '4',
        image: FourthImage,
      },
      {
        key: '5',
        image: SecondImage,
      },
    ],
    sponsors: [
      {
        key: '1',
        logo: CocaCola,
      },
      {
        key: '2',
        logo: Mobil,
      },
      {
        key: '3',
        logo: Xfinity,
      },
      {
        key: '4',
        logo: RedBull,
      },
      {
        key: '5',
        logo: Dew,
      },
      {
        key: '6',
        logo: Airbus,
      },
      {
        key: '7',
        logo: Intel,
      },
      {
        key: '8',
        logo: TMobile,
      },
    ],
  },
  {
    key: '4',
    title: 'Title',
    description:
      'Fusce eget tellus sit amet sem tempor malesuada. Vivamus vel quam venenatis, scelerisque lorem a, maximus lorem. Quisque in tempor massa, vitae egestas purus. Praesent dictum ipsum',
    location:
      'Mimar Hayrettin, Paşa Caddesi & Doğramacı Sokak No:21, 34126 Fatih/İstanbul,',
    images: [
      {
        key: '1',
        image: FirstImage,
      },
      {
        key: '2',
        image: SecondImage,
      },
      {
        key: '3',
        image: ThirdImage,
      },
      {
        key: '4',
        image: FourthImage,
      },
      {
        key: '5',
        image: SecondImage,
      },
    ],
    sponsors: [
      {
        key: '1',
        logo: CocaCola,
      },
      {
        key: '2',
        logo: Mobil,
      },
      {
        key: '3',
        logo: Xfinity,
      },
      {
        key: '4',
        logo: RedBull,
      },
      {
        key: '5',
        logo: Dew,
      },
      {
        key: '6',
        logo: Airbus,
      },
      {
        key: '7',
        logo: Intel,
      },
      {
        key: '8',
        logo: TMobile,
      },
    ],
  },
  {
    key: '5',
    title: 'Title',
    description:
      'Fusce eget tellus sit amet sem tempor malesuada. Vivamus vel quam venenatis, scelerisque lorem a, maximus lorem. Quisque in tempor massa, vitae egestas purus. Praesent dictum ipsum',
    location:
      'Mimar Hayrettin, Paşa Caddesi & Doğramacı Sokak No:21, 34126 Fatih/İstanbul,',
    images: [
      {
        key: '1',
        image: FirstImage,
      },
      {
        key: '2',
        image: SecondImage,
      },
      {
        key: '3',
        image: ThirdImage,
      },
      {
        key: '4',
        image: FourthImage,
      },
      {
        key: '5',
        image: SecondImage,
      },
    ],
    sponsors: [
      {
        key: '1',
        logo: CocaCola,
      },
      {
        key: '2',
        logo: Mobil,
      },
      {
        key: '3',
        logo: Xfinity,
      },
      {
        key: '4',
        logo: RedBull,
      },
      {
        key: '5',
        logo: Dew,
      },
      {
        key: '6',
        logo: Airbus,
      },
      {
        key: '7',
        logo: Intel,
      },
      {
        key: '8',
        logo: TMobile,
      },
    ],
  },
];
