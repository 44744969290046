import React, { useState } from 'react';

import cl from 'classnames';
import { useInterval } from 'utilites';

import s from './styles.module.scss';

export const RightSection = props => {
  const { images } = props;

  const [currentImg, setCurrentImg] = useState(() => {
    if (images.length && images[0]) {
      return {
        ...images[0],
        index: 0,
      };
    }
  });

  useInterval(() => {
    if (currentImg.index < images.length - 1) {
      setCurrentImg(() => ({
        ...images[currentImg.index + 1],
        index: currentImg.index + 1,
      }));
    } else {
      setCurrentImg(() => ({
        ...images[0],
        index: 0,
      }));
    }
  }, 10000);

  const handleChangeImage = imageIdx => {
    setCurrentImg(() => ({ ...images[imageIdx], imageIdx }));
  };

  const mapImages =
    images.length &&
    images.map((image, idx) => {
      const imageClasses = cl(s.imageItem, {
        [s.imageItemActive]: currentImg.key === image.key,
      });

      return (
        <div
          className={imageClasses}
          key={image.key}
          onClick={() => handleChangeImage(idx)}
        >
          <img src={image.image} alt="other" className={s.imageItemElement} />
        </div>
      );
    });

  return (
    <div className={s.rightSection}>
      <div className={s.currentImage}>
        {currentImg && (
          <img
            src={currentImg.image}
            alt="current"
            className={s.currentImageElement}
          />
        )}
      </div>
      <div className={s.listImages}>{mapImages}</div>
    </div>
  );
};
