import React from 'react';

import { useWindowWidth } from 'utilites';

import { VideoPlayer } from '../../VideoPlayer';
import { ActiveContentBigSize } from './ActiveContentBigSize';
import { ActiveContentSmallSize } from './ActiveContentSmallSize';

import './styles.scss';

export const ActiveContent = props => {
  const {
    handleScrollToActiveContent,
    handleScrollToAllContent,
    handleCloseContent,
    openContent,
    isOnScreen,
  } = props;

  const { status, title, url } = openContent;
  const width = useWindowWidth();

  return (
    status && (
      <div
        className={`gmt-home__active-content ${
          isOnScreen && width > 651 ? 'big' : 'small'
        }`}
      >
        <VideoPlayer videoLink={url} />
        {isOnScreen && width > 651 ? (
          <ActiveContentSmallSize
            handleScrollToAllContent={handleScrollToAllContent}
            handleCloseContent={handleCloseContent}
          />
        ) : (
          <ActiveContentBigSize
            handleMaximizeContent={handleScrollToActiveContent}
            handleCloseContent={handleCloseContent}
            title={title}
          />
        )}
      </div>
    )
  );
};
