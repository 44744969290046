const profileTypes = {
  SET_PROFILE_AVATAR: 'profile/SET_PROFILE_AVATAR',
  SET_PROFILE_DATA: 'profile/SET_PROFILE_DATA',

  GET_PROFILE_REQUEST: 'profile/GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS: 'profile/GET_PROFILE_SUCCESS',
  GET_PROFILE_ERROR: 'profile/GET_PROFILE_ERROR',

  PUT_PROFILE_REQUEST: 'profile/PUT_PROFILE_REQUEST',
  PUT_PROFILE_SUCCESS: 'profile/PUT_PROFILE_SUCCESS',
  PUT_PROFILE_ERROR: 'profile/PUT_PROFILE_ERROR',

  CLEAR_PROFILE_PAGE: 'profile/CLEAR_PROFILE_PAGE',
};

export default profileTypes;
