import * as api from '../../../api/about-service/about-service';
import { globalLoaderTypes } from '../../../views/component/Loaders/GlobalLoader/GlobalLoader.config';
import { loadingStart, loadingStop } from '../loading/loadingActions';
import * as partnersActions from './partnersActions';

export const fetchPartnersList = (offset, limit, startLoading) => dispatch => {
  dispatch(partnersActions.getPartnersRequest());
  if (startLoading) dispatch(loadingStart(globalLoaderTypes.secondary));

  return api
    .getPartnersList(offset, limit)
    .then(response => dispatch(partnersActions.getPartnersSuccess(response)))
    .catch(error => dispatch(partnersActions.getPartnersError(error)))
    .finally(() => {
      if (startLoading) {
        dispatch(loadingStart(globalLoaderTypes.tertiary));
        setTimeout(() => dispatch(loadingStop()), 650);
      }
    });
};
