export const sessionTypes = {
  LOGIN_REQUEST: 'session/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'session/LOGIN_SUCCESS',
  LOGIN_SUCCESS_CONFIRM: 'session/LOGIN_SUCCESS_CONFIRM',
  LOGIN_ERROR: 'session/LOGIN_ERROR',
  FORGOT_REQUEST: 'session/FORGOT_REQUEST',
  FORGOT_SUCCESS: 'session/FORGOT_SUCCESS',
  FORGOT_ERROR: 'session/FORGOT_ERROR',
  RESET_REQUEST: 'session/RESET_REQUEST',
  RESET_SUCCESS: 'session/RESET_SUCCESS',
  RESET_ERROR: 'session/RESET_ERROR',
  CONFIRM_REQUEST: 'session/CONFIRM_REQUEST',
  CONFIRM_SUCCESS: 'session/CONFIRM_SUCCESS',
  CONFIRM_ERROR: 'session/CONFIRM_ERROR',
  RE_SEND_CONFIRM_CODE_REQUEST: 'session/ RE_SEND_CONFIRM_CODE_REQUEST',
  RE_SEND_CONFIRM_CODE_SUCCESS: 'session/ RE_SEND_CONFIRM_CODE_SUCCESS',
  RE_SEND_CONFIRM_CODE_ERROR: 'session/ RE_SEND_CONFIRM_CODE_ERROR',
  SIGNUP_REQUEST: 'session/SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'session/SIGNUP_SUCCESS',
  SIGNUP_SUCCESS_CONFIRM: 'session/SIGNUP_SUCCESS_CONFIRM',
  SIGNUP_ERROR: 'session/SIGNUP_ERROR',
  LOGOUT_START: 'session/LOGOUT_START',
  LOGOUT_SUCCESS: 'session/LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'session/LOGOUT_ERROR',
  GET_CURRENT_START: 'session/GET_CURRENT_START',
  GET_CURRENT_SUCCESS: 'session/GET_CURRENT_SUCCESS',
  GET_CURRENT_SUCCESS_CONFIRM: 'session/GET_CURRENT_SUCCESS_CONFIRM',
  GET_CURRENT_ERROR: 'session/GET_CURRENT_ERROR',
  TOKEN_EXPIRATION_STATUS: 'session/TOKEN_EXPIRATION_STATUS',
  SAVE_USER_ID: 'session/SAVE_USER_ID',
};
