import types from './newsTypes';

export const getNewsRequest = () => ({
  type: types.GET_NEWS_REQUEST,
});

export const getNewsSuccess = (data = {}) => ({
  type: types.GET_NEWS_SUCCESS,
  payload: data,
});

export const getNewsError = (error = '') => ({
  type: types.GET_NEWS_ERROR,
  payload: error,
});

export const getNewsByIdSuccess = (data = {}) => ({
  type: types.GET_NEWS_BY_ID_SUCCESS,
  payload: data,
});

export const getNewsByIdError = (error = '') => ({
  type: types.GET_NEWS_BY_ID_ERROR,
  payload: error,
});

export const getNewsByIdRequest = () => ({
  type: types.GET_NEWS_BY_ID_REQUEST,
});

export const postNewsItemLikeRequest = () => ({
  type: types.POST_NEWS_ITEM_LIKE_REQUEST,
});

export const postNewsItemLikeSuccess = payload => ({
  type: types.POST_NEWS_ITEM_LIKE_SUCCESS,
  payload,
});

export const postNewsItemLikeError = (error = '') => ({
  type: types.POST_NEWS_ITEM_LIKE_ERROR,
  payload: error,
});

export const postNewsItemDislikeRequest = () => ({
  type: types.POST_NEWS_ITEM_DISLIKE_REQUEST,
});

export const postNewsItemDislikeSuccess = payload => ({
  type: types.POST_NEWS_ITEM_DISLIKE_SUCCESS,
  payload,
});

export const postNewsItemDislikeError = (error = '') => ({
  type: types.POST_NEWS_ITEM_DISLIKE_ERROR,
  payload: error,
});

export const postNewsItemCommentRequest = () => ({
  type: types.POST_NEWS_ITEM_COMMENT_REQUEST,
});

export const postNewsItemCommentSuccess = payload => ({
  type: types.POST_NEWS_ITEM_COMMENT_SUCCESS,
  payload,
});

export const postNewsItemCommentError = (error = '') => ({
  type: types.POST_NEWS_ITEM_COMMENT_ERROR,
  payload: error,
});

export const postNewsItemCommentLikeRequest = () => ({
  type: types.POST_NEWS_ITEM_COMMENT_LIKE_REQUEST,
});

export const postNewsItemCommentLikeSuccess = payload => ({
  type: types.POST_NEWS_ITEM_COMMENT_LIKE_SUCCESS,
  payload,
});

export const postNewsItemCommentLikeError = (error = '') => ({
  type: types.POST_NEWS_ITEM_COMMENT_LIKE_ERROR,
  payload: error,
});

export const postNewsItemCommentDislikeRequest = () => ({
  type: types.POST_NEWS_ITEM_COMMENT_DISLIKE_REQUEST,
});

export const postNewsItemCommentDislikeSuccess = payload => ({
  type: types.POST_NEWS_ITEM_COMMENT_DISLIKE_SUCCESS,
  payload,
});

export const postNewsItemCommentDislikeError = (error = '') => ({
  type: types.POST_NEWS_ITEM_COMMENT_ERROR,
  payload: error,
});

export const deleteNewsItemCommentRequest = () => ({
  type: types.DELETE_NEWS_ITEM_COMMENT_REQUEST,
});

export const deleteNewsItemCommentSuccess = payload => ({
  type: types.DELETE_NEWS_ITEM_COMMENT_SUCCESS,
  payload,
});

export const deleteNewsItemCommentError = (error = '') => ({
  type: types.DELETE_NEWS_ITEM_COMMENT_ERROR,
  payload: error,
});

export const clearPageDataNewsItem = () => ({
  type: types.CLEAR_PAGE_DATA_NEWS_ITEM,
});

export const getNewsByNameSuccess = (data = {}) => ({
  type: types.GET_NEWS_BY_NAME_SUCCESS,
  payload: data,
});

export const getNewsByNameError = (error = '') => ({
  type: types.GET_NEWS_BY_NAME_ERROR,
  payload: error,
});

export const getNewsByNameRequest = () => ({
  type: types.GET_NEWS_BY_NAME_REQUEST,
});
