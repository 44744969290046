import React from 'react';

import { LeftSection } from './LeftSection';
import { RightSection } from './RightSection';
import { Sponsors } from './Sponsors';

import s from './styles.module.scss';

export const VenueCard = props => {
  const { images, sponsors, ...restProps } = props;
  return (
    <div className={s.venue}>
      <div>
        <div className={s.mainSection}>
          <LeftSection {...restProps} />
          <RightSection images={images} />
        </div>
        {sponsors && sponsors.length && (
          <div>
            <Sponsors sponsors={sponsors} />
          </div>
        )}
      </div>
    </div>
  );
};
