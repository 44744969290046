import profile from '../../../assets/images/profile.png';
import profileTypes from './profileTypes';

const initialState = {
  avatar: profile,
};

export const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case profileTypes.SET_PROFILE_DATA:
      return {
        ...state,
        ...payload,
      };
    case profileTypes.SET_PROFILE_AVATAR:
      return {
        ...state,
        ...payload,
      };
    case profileTypes.PUT_PROFILE_SUCCESS:
      return {
        ...state,
        ...payload,
        avatarFileData: '',
      };
    case profileTypes.CLEAR_PROFILE_PAGE:
      return initialState;
    default:
      return state;
  }
};
