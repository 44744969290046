import React from 'react';

import { ReactComponent as DiscordIcon } from 'assets/images/footer/discord.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/footer/facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/footer/twitter.svg';
import { ReactComponent as VkIcon } from 'assets/images/footer/vk.svg';
import { ReactComponent as YoutubeIcon } from 'assets/images/footer/youtube.svg';

import s from './styles.module.scss';

export const PartnerItem = props => {
  const {
    twitter,
    logo,
    facebook,
    discord,
    youtube,
    vk,
    description,
    site,
    name,
  } = props;

  return (
    <div className={s.partnerItem}>
      <div>
        <a href={site} target="_blank" className={s.logo}>
          <img src={logo} alt="logo" className={s.logoImage} />
        </a>
      </div>
      <div className={s.description}>
        <h4 className={s.descriptionTitle}>{name}</h4>
        {description && <p className={s.descriptionText}>{description}</p>}
      </div>
      <div className={s.socials}>
        {twitter && (
          <a href={twitter} target="_blank" className={s.socialsIcon}>
            <TwitterIcon />
          </a>
        )}
        {youtube && (
          <a href={youtube} target="_blank" className={s.socialsIcon}>
            <YoutubeIcon />
          </a>
        )}
        {discord && (
          <a href={discord} target="_blank" className={s.socialsIcon}>
            <DiscordIcon />
          </a>
        )}
        {vk && (
          <a href={vk} target="_blank" className={s.socialsIcon}>
            <VkIcon />
          </a>
        )}
        {facebook && (
          <a href={facebook} target="_blank" className={s.socialsIcon}>
            <FacebookIcon />
          </a>
        )}
      </div>
    </div>
  );
};
