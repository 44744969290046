import { types } from './bannerTypes';

export const getBannersRequest = () => ({
  type: types.GET_BANNERS_REQUEST,
});

export const getBannersSuccess = (data = {}) => ({
  type: types.GET_BANNERS_SUCCESS,
  payload: data,
});

export const getBannersError = (error = '') => ({
  type: types.GET_BANNERS_ERROR,
  payload: error,
});
