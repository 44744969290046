import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BrandIcon } from 'assets/images/brand-icon.svg';
import { EventCard } from 'views/component';

import s from './styles.module.scss';

export const Events = memo(props => {
  const { tournaments, innerRef } = props;

  const { t } = useTranslation();

  const mapEvents =
    tournaments &&
    tournaments.length &&
    tournaments.map(event => {
      return <EventCard key={event._id} className={s.item} {...event} />;
    });

  return (
    <div className={s.events} ref={innerRef}>
      <div className={s.container}>
        <div className={s.labelBox}>
          <span>
            <BrandIcon className={s.labelIcon} />
          </span>
          <h2 className={s.labelText}>{t('team_players_title')}</h2>
        </div>
        <div className={s.items}>{mapEvents}</div>
      </div>
    </div>
  );
});
