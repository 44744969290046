import React from 'react';

import './styles.scss';

export const Loader = props => {
  const { size = 34 } = props;

  let styleSpinner = { width: size, height: size };

  return (
    <div className="spinner-container">
      <div className="spinner" style={styleSpinner}></div>
    </div>
  );
};
