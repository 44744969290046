import React from 'react';

import { useWindowWidth } from './customHooks';

const typeOfAdaptive = {
  FULLHD: 'titleImage_FULLHD',
  DESKTOP: 'titleImage_DESKTOP',
  LAPTOP: 'titleImage_LAPTOP',
  TABLET: 'titleImage_TABLET',
  BIGPHONE: 'titleImage_BIGPHONE',
  PHONE: 'titleImage_PHONE',
  SMALLPHONE: 'titleImage_SMALLPHONE',
};

const TrackAdaptiveImage = ({ titleImage = [], className = '' }) => {
  const width = useWindowWidth();

  const handleReturnAdaptiveImage = () => {
    // switch (true) {
    //   case width < 320:
    //     return typeOfAdaptive.SMALLPHONE;
    //   case width < 375:
    //     return typeOfAdaptive.PHONE;
    //   case width < 428:
    //     return typeOfAdaptive.BIGPHONE;
    //   case width < 768:
    //     return typeOfAdaptive.TABLET;
    //   case width < 1024:
    //     return typeOfAdaptive.TABLET;
    //   case width < 1440:
    //     return typeOfAdaptive.DESKTOP;
    //   case width < 2560:
    //     return typeOfAdaptive.FULLHD;
    //   default:
    return typeOfAdaptive.FULLHD;
    // }
  };

  const { url } = titleImage.find(
    ({ type }) => type === handleReturnAdaptiveImage(),
  );

  return <img src={url} alt="banner" className={className} />;
};

export default TrackAdaptiveImage;
