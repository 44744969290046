import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import { setSiteLanguage } from 'utilites';

import translationAz from './az/translation';
import translationEn from './en/translation';
import translationRu from './ru/translation';

const resources = {
  ru: {
    translation: translationRu,
  },
  az: {
    translation: translationAz,
  },
  en: {
    translation: translationEn,
  },
};

const initializeLanguage = setSiteLanguage();

i18n.use(initReactI18next).init({
  resources,
  lng: initializeLanguage,
  interpolation: {
    escapeValue: false,
  },

  react: {
    useSuspense: false,
  },
});

export default i18n;
