const types = {
  GET_ALBUMS_REQUEST: 'albums/GET_ALBUMS_REQUEST',
  GET_ALBUMS_SUCCESS: 'albums/GET_ALBUMS_SUCCESS',
  GET_ALBUMS_ERROR: 'albums/GET_ALBUMS_ERROR',

  GET_ALBUM_BY_ID_REQUEST: 'albums/GET_ALBUM_BY_ID_REQUEST',
  GET_ALBUM_BY_ID_SUCCESS: 'albums/GET_ALBUM_BY_ID_SUCCESS',
  GET_ALBUM_BY_ID_ERROR: 'albums/GET_ALBUM_BY_ID_ERROR',

  GET_PHOTO_BY_ID_REQUEST: 'albums/GET_PHOTO_BY_ID_REQUEST',
  GET_PHOTO_BY_ID_SUCCESS: 'albums/GET_PHOTO_BY_ID_SUCCESS',
  GET_PHOTO_BY_ID_ERROR: 'albums/GET_PHOTO_BY_ID_ERROR',

  POST_ALBUM_ITEM_COMMENT_REQUEST: 'albums/POST_ALBUM_ITEM_COMMENT_REQUEST',
  POST_ALBUM_ITEM_COMMENT_SUCCESS: 'albums/POST_ALBUM_ITEM_COMMENT_SUCCESS',
  POST_ALBUM_ITEM_COMMENT_ERROR: 'albums/POST_ALBUM_ITEM_COMMENT_ERROR',

  POST_ALBUM_ITEM_LIKE_REQUEST: 'albums/POST_ALBUM_ITEM_LIKE_REQUEST',
  POST_ALBUM_ITEM_LIKE_SUCCESS: 'albums/POST_ALBUM_ITEM_LIKE_SUCCESS',
  POST_ALBUM_ITEM_LIKE_ERROR: 'albums/POST_NEWS_ALBUM_LIKE_ERROR',

  POST_ALBUM_ITEM_DISLIKE_REQUEST: 'albums/POST_ALBUM_ITEM_DISLIKE_REQUEST',
  POST_ALBUM_ITEM_DISLIKE_SUCCESS: 'albums/POST_ALBUM_ITEM_DISLIKE_SUCCESS',
  POST_ALBUM_ITEM_DISLIKE_ERROR: 'albums/POST_ALBUM_ITEM_DISLIKE_ERROR',

  POST_ALBUM_ITEM_COMMENT_LIKE_REQUEST:
    'albums/POST_ALBUM_ITEM_COMMENT_LIKE_REQUEST',
  POST_ALBUM_ITEM_COMMENT_LIKE_SUCCESS:
    'albums/POST_ALBUM_ITEM_COMMENT_LIKE_SUCCESS',
  POST_ALBUM_ITEM_COMMENT_LIKE_ERROR:
    'albums/POST_ALBUM_ITEM_COMMENT_LIKE_ERROR',

  POST_ALBUM_ITEM_COMMENT_DISLIKE_REQUEST:
    'albums/POST_ALBUM_ITEM_COMMENT_DISLIKE_REQUEST',
  POST_ALBUM_ITEM_COMMENT_DISLIKE_SUCCESS:
    'albums/POST_ALBUM_ITEM_COMMENT_DISLIKE_SUCCESS',
  POST_ALBUM_ITEM_COMMENT_DISLIKE_ERROR:
    'albums/POST_ALBUM_ITEM_COMMENT_DISLIKE_ERROR',

  DELETE_ALBUM_ITEM_COMMENT_REQUEST: 'albums/DELETE_ALBUM_ITEM_COMMENT_REQUEST',
  DELETE_ALBUM_ITEM_COMMENT_SUCCESS: 'albums/DELETE_ALBUM_ITEM_COMMENT_SUCCESS',
  DELETE_ALBUM_ITEM_COMMENT_ERROR: 'albums/DELETE_ALBUM_ITEM_COMMENT_ERROR',
};
export default types;
