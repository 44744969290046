import React, { useEffect, useState } from 'react';

import { ReactComponent as ArrowDownIcon } from 'assets/images/header-arrow-down.svg';
import { scrollTop } from 'utilites';

import './styles.scss';

export const ScrollButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);

    return () => window.removeEventListener('scroll', checkScrollTop);
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  return (
    <>
      {showScroll && (
        <button onClick={() => scrollTop()} className="scroll-button">
          <ArrowDownIcon className="scroll-button__icon" />
        </button>
      )}
    </>
  );
};
