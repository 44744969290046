import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cl from 'classnames';
import {
  allTournamentsLoaderSelector,
  allTournamentsSelector,
} from 'redux/selectors';
import { fetchTournamentsList } from 'redux/store/tournaments/tournamentsOperations';
import { createArrayWithLength } from 'utilites/createArrayWithLength';
import { EventCard, Loader, LoadMore } from 'views/component';

import s from './styles.module.scss';

export const Cards = () => {
  const dispatch = useDispatch();

  const { items, count } = useSelector(allTournamentsSelector);
  const isLoading = useSelector(allTournamentsLoaderSelector);

  const [limit, setLimit] = useState(6);

  const mapPlugs =
    count > 0 &&
    isLoading &&
    createArrayWithLength(3, (_, idx) => {
      return (
        <div key={idx} className={cl(s.item, s.itemLoader)}>
          <Loader />
        </div>
      );
    });

  const mapEvents =
    count > 0 &&
    items.map(event => {
      return <EventCard key={event._id} className={s.item} {...event} />;
    });

  const handleClickLoadMore = () => {
    const updatedLimit = limit + 6;

    dispatch(fetchTournamentsList(0, updatedLimit, false));

    setLimit(updatedLimit);
  };

  return (
    <div className={s.cards}>
      <div className={s.container}>
        {mapEvents}
        {mapPlugs}
        <LoadMore
          canShow={count > limit}
          isLoading={isLoading}
          onClick={handleClickLoadMore}
          disableAnimation
        />
      </div>
    </div>
  );
};
