import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import book from 'constants/book';
import { globalLoading, notificationSelector } from 'redux/selectors';
import { fetchSiteLanguage } from 'redux/store/language/languageOperations';
import { getCurrentUser } from 'redux/store/session/sessionOperations';
import * as smoothscroll from 'smoothscroll-polyfill';
import {
  Footer,
  GlobalLoader,
  Header,
  NotificationModal,
  PromoVideo,
  ScrollButton,
  ScrollToTop,
} from 'views/component';

import { privateRoutes, publicRoutes } from './config';
import { PrivateRoute } from './PrivateRoute';

import './styles.scss';

const Navigation = ({
  globalLoader: { isLoading },
  notification: {
    status: notificationState,
    message: notificationMessage,
    notificationType,
  },
  fetchSiteLanguage,
}) => {
  smoothscroll.polyfill();

  const [isPromo, setIsPromo] = useState(() => sessionStorage.getItem('promo'));

  useLayoutEffect(() => fetchSiteLanguage(), [fetchSiteLanguage]);

  return !isPromo ? (
    <PromoVideo setIsPromo={setIsPromo} />
  ) : (
    <>
      {isLoading && <GlobalLoader />}
      <ScrollToTop />
      <Header className="layout__header" />
      {notificationState && (
        <NotificationModal
          notificationType={notificationType}
          message={notificationMessage}
        />
      )}
      <ScrollButton />
      <div className="layout__router">
        <Switch>
          {publicRoutes.map(({ path, component, isExact }) => (
            <Route
              key={path}
              path={path}
              exact={isExact}
              component={component}
            />
          ))}

          {privateRoutes.map(({ path, component, isExact, redirectPath }) => (
            <PrivateRoute
              key={path}
              path={path}
              exact={isExact}
              component={component}
              redirectPath={redirectPath}
            />
          ))}
          <Redirect to={book.landing} />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = state => ({
  globalLoader: globalLoading(state),
  notification: notificationSelector(state),
});

const mapDispatchToProps = {
  getCurrentUser,
  fetchSiteLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
