import { useEffect, useState } from 'react';

export const useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => setSrc(highQualitySrc);
    return () => setSrc('');
  }, [lowQualitySrc, highQualitySrc]);

  return src;
};
