import types from './videoTypes';

export const getVideoRequest = () => ({
  type: types.GET_VIDEO_REQUEST,
});
export const getVideoListSuccess = (data = {}) => ({
  type: types.GET_VIDEO_LIST_SUCCESS,
  payload: data,
});

export const getVideoListError = (error = '') => ({
  type: types.GET_VIDEO_LIST_ERROR,
  payload: error,
});

export const saveVideoTypeAndUrl = (data = {}) => ({
  type: types.SAVE_VIDEO_TYPE_AND_URL,
  payload: data,
});
export const getVideoSuccess = data => ({
  type: types.GET_VIDEO_ITEM_REQUEST,
  payload: data,
});
export const getVideoError = (error = '') => ({
  type: types.GET_VIDEO_ERROR,
  payload: error,
});
export const editVideoCommentsList = commentList => ({
  type: types.EDIT_VIDEO_COMMENT,
  payload: commentList,
});

export const postVideoNewComment = commentList => ({
  type: types.POST_VIDEO_COMMENT,
  payload: commentList,
});

export const clearPageDataVideoItem = () => ({
  type: types.CLEAR_PAGE_DATA_VIDEO_ITEM,
});

export const getRelatedVideoListRequest = () => ({
  type: types.GET_RELATED_VIDEO_LIST_REQUEST,
});
export const getRelatedVideoListSuccess = (data = {}) => ({
  type: types.GET_RELATED_VIDEO_LIST_SUCCESS,
  payload: data,
});

export const getRelatedVideoListError = (data = {}) => ({
  type: types.GET_RELATED_VIDEO_LIST_ERROR,
  payload: data,
});

export const deleteVideoCommentRequest = () => ({
  type: types.DELETE_VIDEO_COMMENT_REQUEST,
});

export const deleteVideoCommentSuccess = payload => ({
  type: types.DELETE_VIDEO_COMMENT_SUCCESS,
  payload,
});

export const deleteVideoCommentError = (error = '') => ({
  type: types.DELETE_VIDEO_COMMENT_ERROR,
  payload: error,
});

export const clearRelatedVideoList = () => ({
  type: types.CLEAR_RELATED_VIDEO_LIST,
});
