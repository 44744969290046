import React, { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'; // Animation

import arrowDownAnimation from 'animation/iconsLoader/arrowDown.json';
import {
  bannersListSelector,
  liveStreamsSelector,
  videoSelector,
} from 'redux/selectors';
import { fetchBanners } from 'redux/store/banner/bannerOperations';
import { fetchLiveStream } from 'redux/store/live/liveOperations';
import { fetchVideoList } from 'redux/store/video/videoOperations';
import { useStateWithCallback } from 'utilites';
import TrackAdaptiveImage from 'utilites/trackAdaptiveImage';
import { Banner, LottieAnimation, MediaContent } from 'views/component';

import './Landing.styles.scss';

const Landing = () => {
  const { t } = useTranslation();

  const liveStreamList = useSelector(liveStreamsSelector);
  const bannersList = useSelector(bannersListSelector);
  const videoList = useSelector(videoSelector);
  const dispatch = useDispatch();

  const scrollToFooter = useCallback(() => {
    const scrollTo = document.querySelector('.gmt-home__banner');
    scrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchVideoList(0, 6, '', 'VIDEO'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLiveStream(0, 6));
    const updateLiveStreams = setInterval(() => {
      dispatch(fetchLiveStream(0, 6));
    }, 300000);

    return () => {
      setInterval(clearTimeout, updateLiveStreams);
    };
  }, [dispatch]);

  const [openContent, setOpenContent] = useStateWithCallback({
    status: false,
    url: null,
    title: '',
    type: 'none',
    id: null,
  });

  return (
    <div className="gmt-home">
      <div className="gmt-home__welcome">
        <div className="gmt-home__welcome-info">
          <div className="gmt-home__welcome-info-topLine">
            <div className="gmt-home__welcome-info-title">
              {t('gmt_header_welcome_to')}
            </div>
            <div className="gmt-home__welcome-info-name">
              {t('gmt_header_name')}
            </div>
          </div>
          <div className="gmt-home__welcome-arrow">
            <LottieAnimation
              onClick={scrollToFooter}
              className="gmt-home__welcome-arrow-image"
              lotti={arrowDownAnimation}
            />
          </div>
        </div>
      </div>

      <div className="gmt-home__banner">
        <Banner>
          {bannersList &&
            bannersList.map(({ titleImage, urlByName, title }) => (
              <Fragment key={urlByName}>
                <div className="new-slider__container-img">
                  {Array.isArray(titleImage) ? (
                    <TrackAdaptiveImage titleImage={titleImage} />
                  ) : (
                    <img src={titleImage} alt="banner" />
                  )}
                </div>
                <span>{title}</span>
              </Fragment>
            ))}
        </Banner>
      </div>

      <div className="gmt-home__media">
        <div className="gmt-home__media-content">
          {liveStreamList.count > 0 && (
            <MediaContent
              setOpenContent={setOpenContent}
              listOfItems={liveStreamList}
              openContent={openContent}
              adaptiveSlides={1}
              type="stream"
              slides={1}
            />
          )}
          {videoList.count > 0 && (
            <MediaContent
              setOpenContent={setOpenContent}
              openContent={openContent}
              listOfItems={videoList}
              adaptiveSlides={1}
              type="video"
              slides={3}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Landing;
