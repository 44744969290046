import { useMemo, useState } from 'react';

export const usePagination = options => {
  const { defaultPage = 1, pageSize = 6, data = [] } = options || {};

  const [currentPage, setCurrentPage] = useState(defaultPage);

  const paginationData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const onPageChange = page => {
    setCurrentPage(page);
  };

  return {
    currentPage,
    onPageChange,
    paginationData,
  };
};
